<template>
    <b-modal
      id="secondary-procedure-aih-modal"
      hide-header
      hide-footer
      centered
      size="lg"
      @show="onShow"
      @hidden="closeModal"
    >
      <div class="modal-header">
        <div class="modal-title">
          <span v-if="procedure?.id">Atualizar</span>
          <span v-else>Novo Procedimento</span>
        </div>
        <span class="icon-close">
          <Close class="icon stroke" @click="closeModal" />
        </span>
      </div>
  
      <div class="around-content">
        <b-row class="mt-3">
          <b-col cols="12">
            <p class="form-title mb-3">
              Dados da execução do procedimento
            </p>
          </b-col>

          <b-row class="px-2">
              <b-col cols="5">
                <b-form-group label="Indicador do profissional (CPF ou CNS)" :state="errors.professional_indicator ? false : null">
                  <multiselect
                    id="professional_indicator"
                    v-model="selectedProfessionalIndicator"
                    :options="professionalIndicatorOptions"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    track-by="code"
                    label="label"
                    placeholder="Selecionar"
                    class="with-border"
                    ref="professional_indicator"
                    >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>

                    <template slot="noOptions"> Nenhuma opção </template>

                    <template slot="noResult"> Nenhum resultado </template>
                  </multiselect>
                  <b-form-invalid-feedback v-if="errors?.professional_indicator">
                    {{ errors.professional_indicator }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="N° do documento do profissional">
                  <b-form-input
                    v-model="procedure.professional_document_number"
                    placeholder="000000000000000"
                    autocomplete="off"
                    v-mask="getMaskProfessional"
                    :state="errors.professional_document_number ? false : null"
                    ref="professional_document_number"
                  />
                  <b-form-invalid-feedback v-if="errors?.professional_document_number">
                    {{ errors.professional_document_number }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group label="CBO do profissional">
                  <b-form-input
                    v-model="procedure.professional_cbo"
                    placeholder="000000"
                    autocomplete="off"
                    v-mask="'######'"
                    :state="errors.professional_cbo ? false : null"
                    ref="professional_cbo"
                  />
                  <b-form-invalid-feedback v-if="errors?.professional_cbo">
                    {{ errors.professional_cbo }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group label="Indicador da equipe" :state="errors.team_indicator ? false : null">
                  <multiselect
                    id="team_indicator"
                    v-model="selectedTeamIndicator"
                    :options="teamIndicatorOptions"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    track-by="code"
                    label="label"
                    placeholder="Selecionar"
                    class="with-border"
                    ref="team_indicator"
                    >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>

                    <template slot="noOptions"> Nenhuma opção </template>

                    <template slot="noResult"> Nenhum resultado </template>
                  </multiselect>
                  <b-form-invalid-feedback v-if="errors?.team_indicator">
                    {{ errors.team_indicator }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Indicador do prestador de serviço" :state="errors.service_provider_indicator ? false : null">
                  <multiselect
                    id="service_provider_indicator"
                    v-model="selectedServiceProviderIndicator"
                    :options="serviceProviderIndicatorOptions"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    track-by="code"
                    label="label"
                    placeholder="Selecionar"
                    class="with-border"
                    ref="service_provider_indicator"
                    >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>

                    <template slot="noOptions"> Nenhuma opção </template>

                    <template slot="noResult"> Nenhum resultado </template>
                  </multiselect>
                  <b-form-invalid-feedback v-if="errors?.service_provider_indicator">
                    {{ errors.service_provider_indicator }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="5">
                <b-form-group label="Identificação do prestador de serviço">
                  <b-form-input
                    v-model="procedure.identification_service_provider"
                    placeholder="000000000000000"
                    autocomplete="off"
                    v-mask="getMaskServiceProvider"
                    :state="errors.identification_service_provider ? false : null"
                    ref="identification_service_provider"
                  />
                  <b-form-invalid-feedback v-if="errors?.identification_service_provider">
                    {{ errors.identification_service_provider }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Indicador do prestador de executor" :state="errors.executor_provider_indicator ? false : null">
                  <multiselect
                    id="executor_provider_indicator"
                    v-model="selectedExecutorProviderIndicator"
                    :options="executorProviderIndicatorOptions"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    track-by="code"
                    label="label"
                    placeholder="Selecionar"
                    class="with-border"
                    ref="executor_provider_indicator"
                    >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>

                    <template slot="noOptions"> Nenhuma opção </template>

                    <template slot="noResult"> Nenhum resultado </template>
                  </multiselect>
                  <b-form-invalid-feedback v-if="errors?.executor_provider_indicator">
                    {{ errors.executor_provider_indicator }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Documento do executor">
                  <b-form-input
                    v-model="procedure.executor_document"
                    placeholder="000000000000000"
                    autocomplete="off"
                    v-mask="getMaskExecutorProvider"
                    :state="errors.executor_document ? false : null"
                    ref="executor_document"
                  />
                  <b-form-invalid-feedback v-if="errors?.executor_document">
                    {{ errors.executor_document }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
          </b-row>

          <b-col cols="10">
            <b-form-group label="Procedimento">
              <ItemHealthPlanSimpleSelector 
                  :clinicHealthPlanId="clinicHealthPlanId"
                  :allowedTypes="['PROCEDURE','APPOINTMENT', 'EXAM', 'RETURN']"
                  v-model="procedure.item"
                  @select="setGuideItem"
                  :state="errors.procedure_item ? false : null"
                  ref="procedure_item"
              />
              <b-form-invalid-feedback v-if="errors?.procedure_item">
                {{ errors.procedure_item }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Qtde.">
              <b-form-input
                v-model="procedure.quantity"
                placeholder="00"
                autocomplete="off"
                type="number"
                min="1"
                :state="errors.procedure_quantity ? false : null"
                ref="procedure_quantity"
              />
              <b-form-invalid-feedback v-if="errors?.procedure_quantity">
                {{ errors.procedure_quantity }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Valor Unitário">
              <money 
                id="unitary_value"
                v-model="procedure.unitary_value" 
                class="form-control"
                :state="errors.procedure_unitary_value ? false : null"
                ref="procedure_unitary_value"
              />
              <b-form-invalid-feedback v-if="errors?.procedure_unitary_value">
                {{ errors.procedure_unitary_value }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Valor Total">
              <money 
                id="unitary_value"
                v-model="procedure.total" 
                class="form-control"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Data de atendimento">
              <date-picker
                placeholder="Selecionar"
                id="start_datetime"
                v-model="procedure.attendance_date"
                format="DD/MM/YYYY"
                :clearable="false"
                class="full"
                :readonly="false"
                :state="errors.procedure_attendance_date ? false : null"
                ref="attendance_date"
              />
              <b-form-invalid-feedback v-if="errors?.procedure_attendance_date">
                {{ errors.procedure_attendance_date }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
  
      <div class="wrapper-button text-center mt-3">
        <b-button variant="primary" size="lg" @click="saveProcedure" v-if="readonly">
          Salvar procedimento
        </b-button>
      </div>
    </b-modal>
  </template>
  
  <script>
  import moment from 'moment';

  export default {
    name: 'SecondaryProcedureAihModal',
    components: {
      Close: () => import('@/assets/icons/close.svg'),
      ChevronDown: () => import('@/assets/icons/chevron-down-select.svg'),
      ItemHealthPlanSimpleSelector: () => import('@items/components/ItemHealthPlanSimpleSelector'),
    },
    computed: {
      getMaskProfessional() {
        return this.selectedProfessionalIndicator?.label === 'CPF' ? '###.###.###-##' : '###############';
      },
      getMaskServiceProvider() {
        const maskMap = {
          '3': '##.###.###/####-##',
        };

        return maskMap[this.selectedServiceProviderIndicator?.code] || '##############';
      },
      getMaskExecutorProvider() {
        const maskMap = {
          '1': '###.###.###-##',
          '3': '##.###.###/####-##',
        };

        return maskMap[this.selectedExecutorProviderIndicator?.code] || '###############';
      },
    },
    data() {
      return {
        professionalIndicatorOptions: [
            {
                code: 'cpf',
                label: 'CPF'
            },
            {
                code: 'cns',
                label: 'CNS'
            }
        ],
        teamIndicatorOptions: [
            {
                code: '0',
                label: '0-Não Aplicavel',
            },
            {
                code: '1',
                label: '1-Primeiro Cirurgião'
            },
            {
                code: '2',
                label: '2-Primeiro Auxiliar'
            },
            {
                code: '3',
                label: '3-Segundo Auxiliar',
            },
            {
                code: '4',
                label: '4-Terceiro Auxiliar'
            },
            {
                code: '5',
                label: '5-Quarto Auxiliar'
            },
            {
                code: '6',
                label: '6-Anestesista'
            }
        ],
        serviceProviderIndicatorOptions: [
            {
                code: '0',
                label: '0-Não Aplicavel',
            },
            {
                code: '3',
                label: '3- CNPJ'
            },
            {
                code: '5',
                label: '5- CNES'
            }
        ],
        executorProviderIndicatorOptions: [
            {
                code: '1',
                label: '1-CPF',
            },
            {
                code: '2',
                label: '2-CNS'
            },
            {
                code: '3',
                label: '3-CNPJ'
            },
            {
                code: '5',
                label: '5- CNES'
            }
        ],
        changeProcedureOptions: [
          {
            'code': '1',
            'label': '1 - Sim'
          },
          {
            'code': '2',
            'label': '2 - Não'
          }                
        ],
        selectedProfessionalIndicator: null,
        selectedTeamIndicator: null,
        selectedServiceProviderIndicator: null,
        selectedExecutorProviderIndicator: null,
        errors: {},
        langDatePicker: {
          formatLocale: {
            months: moment.months(),
            monthsShort: moment.monthsShort(),
          },
        },
      }
    },
    props: {
      type: String,
      procedure: {
        type: Object,
        default: () => ({}),
      },
      readonly: Boolean,
      clinicHealthPlanId: String,
      requestFields: {},
      isValidCPF: Function,
      isValidCNPJ:Function,
    },
    mounted (){    
    },
    methods: {
      setGuideItem(item) {
        this.procedure.item = item;
        this.procedure.code = item.code;
        this.procedure.item_id = item.id;
        this.procedure.name = item.name;
        this.procedure.quantity = 1;
        this.procedure.unitary_value = item.health_plan_props.value;
      },
      onShow() {
      },
      closeModal() {
        this.selectedExecutorProviderIndicator =  null;
        this.selectedProfessionalIndicator = null;
        this.selectedServiceProviderIndicator =  null;
        this.selectedTeamIndicator =  null;
        this.procedure_unitary_value = null;
        this.$bvModal.hide('secondary-procedure-aih-modal')
      },
      saveProcedure() {
        if (this.isValidForm()) {
          this.$toast.warning('Preencha todos os campos obrigatórios.')
          return
        }

        this.$emit('update-secondary-procedures', { 
          ...this.procedure,
          professional_indicator_code: this.selectedProfessionalIndicator?.code,
          team_indicator_code: this.selectedTeamIndicator?.code,
          service_provider_indicator_code: this.selectedServiceProviderIndicator?.code,
          executor_provider_indicator_code: this.selectedExecutorProviderIndicator?.code
        });  

        this.closeModal();
      },
      isValidForm() {
        const errors = {};

        if(this.selectedProfessionalIndicator?.label === 'CPF'){
          if(!this.isValidCPF(this.procedure.professional_document_number)){
            errors.professional_document_number = "CPF Inválido.";
          }
        }

        if(this.selectedServiceProviderIndicator?.code === '3'){
          if(!this.isValidCNPJ(this.procedure.identification_service_provider)){
            errors.identification_service_provider = "CNPJ Inválido.";
          }
        }

        if(this.selectedExecutorProviderIndicator?.code === '3'){
          if(!this.isValidCNPJ(this.procedure.executor_document)){
            errors.executor_document = "CNPJ Inválido.";
          }
        }

        if(this.selectedExecutorProviderIndicator?.code === '1'){
          if(!this.isValidCPF(this.procedure.executor_document)){
            errors.executor_document = "CPF Inválido.";
          }
        }
        
        if(
          !this.procedure.professional_indicator &&
          !this.procedure.professional_document_number &&
          !this.procedure.professional_cbo &&
          !this.procedure.team_indicator &&
          !this.procedure.service_provider_indicator &&
          !this.procedure.identification_service_provider &&
          !this.procedure.executor_provider_indicator &&
          !this.procedure.executor_document &&
          !this.procedure.procedure_item &&
          !this.procedure.quantity
      ){
        errors.fields = 'Não é permitido adicionar procedimento secundário vazio';
      }

      if(!this.selectedProfessionalIndicator){
        errors.professional_indicator = "Campo obrigatório";
      }
      
      if(!this.procedure.professional_document_number){
        errors.professional_document_number = "Campo obrigatório";
      }

      if(!this.procedure.professional_cbo){
        errors.professional_cbo = "Campo obrigatório";
      }

      if(!this.selectedTeamIndicator){
        errors.team_indicator = "Campo obrigatório";
      }

      if(!this.selectedServiceProviderIndicator){
        errors.service_provider_indicator = "Campo obrigatório";
      }

      if(!this.procedure.identification_service_provider){
        errors.identification_service_provider = "Campo obrigatório";
      }

      if(!this.selectedExecutorProviderIndicator){
        errors.executor_provider_indicator = "Campo obrigatório";
      }

      if(!this.procedure.executor_document){
        errors.executor_document = "Campo obrigatório";
      }

      if(!this.procedure.item){
        errors.procedure_item = "Campo obrigatório";
      }

      if(!this.procedure.quantity){
        errors.procedure_quantity = "Campo obrigatório";
      }

      Object.keys(this.requestFields).map(item => {
        if(this.$refs[item] && (!this.$refs[item].value || this.$refs[item].value.length === 0) && this.requestFields[item]){
          errors[item] = "Campo obrigatório";
        }
      });

      this.errors = errors;

      return Object.keys(errors).length;
      }
    },
    watch: {
      'procedure'() {
        if(this.procedure.professional_indicator_code){
          this.selectedProfessionalIndicator = this.professionalIndicatorOptions.find(item => item.code === this.procedure.professional_indicator_code);
        }

        if(this.procedure.team_indicator_code){
          this.selectedTeamIndicator = this.teamIndicatorOptions.find(item => item.code === this.procedure.team_indicator_code);
        }
        
        if(this.procedure.service_provider_indicator_code){
          this.selectedServiceProviderIndicator = this.serviceProviderIndicatorOptions.find(item => item.code === this.procedure.service_provider_indicator_code);
        }

        if(this.procedure.executor_provider_indicator_code){
          this.selectedExecutorProviderIndicator = this.executorProviderIndicatorOptions.find(item => item.code === this.procedure.executor_provider_indicator_code);
        }
      },
      'procedure.quantity'() {
        this.procedure.total = this.procedure.unitary_value * this.procedure.quantity
      },
      'procedure.unitary_value'() {
        this.procedure.total = this.procedure.unitary_value * this.procedure.quantity
      },
    }
  };
  </script>
  <style lang="scss">
    #secondary-procedure-aih-modal {
      .form-title {
        color: var(--neutral-600);
        font-size: 18px;
        font-weight: bolder;
        line-height: 28px;
      }
      .modal-header {
        .modal-title {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
        }

        .icon-close {
          width: 25px;

          svg {
            fill: var(--neutral-500);
          }
        }
      }
      
      .col-form-label {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          color: #0a184c;
          margin: 0;
          margin-bottom: 0px;
      }

      .invalid-feedback{
        display: block;
      }
  }
  </style>