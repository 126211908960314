<template>
    <b-modal
      id="procedure-aih-modal"
      hide-header
      hide-footer
      centered
      size="lg"
      @show="onShow"
      @hidden="closeModal"
    >
      <div class="modal-header">
        <div class="modal-title">
          <span v-if="procedure?.id">Atualizar</span>
          <span v-else>Novo Procedimento</span>
        </div>
        <span class="icon-close">
          <Close class="icon stroke" @click="closeModal" />
        </span>
      </div>
  
      <div class="around-content">
        <b-row class="mt-3">
          <b-col cols="12">
            <p class="form-title mb-3">
              Dados da execução do procedimento
            </p>
          </b-col>
          
          <b-row class="px-2">
            <b-col class="mt-3" cols="4">
              <b-form-group>
                <label for="bed_specialty">Especialidade do leito</label>
                <multiselect
                  id="bed_specialty"
                  autocomplete="off"
                  v-model="selectedBedSpecialty"
                  :options="bedSpecialtyOptions"
                  track-by="code"
                  label="label"
                  :option-height="40"
                  :showLabels="false"
                  :searchable="true"
                  placeholder="Selecione um tipo"
                  class="with-border"
                  :state="errors?.bed_specialty ? false : null"
                  ref="bed_specialty"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>
                  <template slot="noOptions"> Nenhuma opção </template>
                  <template slot="noResult"> Nenhum resultado </template>
                </multiselect>
                <div 
                  v-if="errors.bed_specialty ? true : false"
                  class="custom-invalid-feedback"
                >
                  Campo obrigatório
                </div>
              </b-form-group>
            </b-col>
            <b-col class="mt-3" cols="4">
              <b-form-group>
                <label for="date_admission">Data da internação</label>
                <date-picker
                  placeholder="Selecionar"
                  v-model="procedure.date_admission"
                  name="date_admission"
                  format="DD/MM/YYYY"
                  value-type="date"
                  id="date_admission"
                  :lang="langDatePicker"
                  :clearable="false"
                  class="full"
                  :state="errors?.date_admission ? false : null"
                  ref="date_admission"
                />
                <b-form-invalid-feedback :state="errors.date_admission ? false : null">
                  {{ errors.date_admission }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col class="mt-3" cols="4">
              <b-form-group>
                <label for="exit_date">
                  Data de saída
                </label>
                <date-picker
                  placeholder="Selecionar"
                  v-model="procedure.exit_date"
                  name="exit_date"
                  format="DD/MM/YYYY"
                  value-type="date"
                  id="exit_date"
                  :lang="langDatePicker"
                  :clearable="false"
                  class="full"
                  :state="errors?.exit_date ? false : null"
                  ref="exit_date"
                />
                <div 
                  v-if="errors.exit_date ? true : false"
                  class="custom-invalid-feedback"
                >
                  Campo obrigatório
                </div>
              </b-form-group>
            </b-col> 
            <b-col class="mt-3" cols="6">
              <b-form-group>
                <label for="exit_stay_reason">Motivo de saída/permanência</label>
                <multiselect
                  id="exit_stay_reason"
                  autocomplete="off"
                  v-model="selectedExitStayReason"
                  :options="exitStayReasonOptions"
                  track-by="code"
                  label="label"
                  :option-height="40"
                  :showLabels="false"
                  :searchable="true"
                  placeholder="Selecione um tipo"
                  class="with-border"
                  :state="errors?.exit_stay_reason ? false : null"
                  ref="exit_stay_reason"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>
                  <template slot="noOptions"> Nenhuma opção </template>
                  <template slot="noResult"> Nenhum resultado </template>
                </multiselect>
                <div 
                  v-if="errors.exit_stay_reason ? true : false"
                  class="custom-invalid-feedback"
                >
                  Campo obrigatório
                </div>
              </b-form-group>
            </b-col>
            <b-col class="mt-3" cols="6">
              <b-form-group>
                <label for="change_procedure">Mudança de procedimento</label>
                <multiselect
                  id="change_procedure"
                  autocomplete="off"
                  v-model="selectedChangeProcedure"
                  :options="changeProcedureOptions"
                  track-by="code"
                  label="label"
                  :option-height="40"
                  :showLabels="false"
                  :searchable="true"
                  placeholder="Selecione um tipo"
                  class="with-border"
                  :state="errors?.change_procedure ? false : null"
                  ref="change_procedure"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>
                  <template slot="noOptions"> Nenhuma opção </template>
                  <template slot="noResult"> Nenhum resultado </template>
                </multiselect>
                <div 
                  v-if="errors.change_procedure ? true : false"
                  class="custom-invalid-feedback"
                >
                  Campo obrigatório
                </div>
              </b-form-group>
            </b-col>
            <b-col class="mt-3" cols="4">
              <b-form-group>
                <label for="character_hospitalization">Caráter da internação</label>
                <multiselect
                  id="character_hospitalization"
                  autocomplete="off"
                  v-model="selectedCharacterHospitalization"
                  :options="characterHospitalizationOptions"
                  track-by="code"
                  label="label"
                  :option-height="40"
                  :showLabels="false"
                  :searchable="true"
                  placeholder="Selecione um tipo"
                  class="with-border"
                  :state="errors?.character_hospitalization ? false : null"
                  ref="character_hospitalization"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>
                  <template slot="noOptions"> Nenhuma opção </template>
                  <template slot="noResult"> Nenhum resultado </template>
                </multiselect>
                <div 
                  v-if="errors.character_hospitalization ? true : false"
                  class="custom-invalid-feedback"
                >
                  Campo obrigatório
                </div>
              </b-form-group>
            </b-col>
            <b-col class="mt-3" cols="4">
              <b-form-group>
                <label for="clinic_requested_procedure">Clínica</label>
                <b-form-input
                  placeholder="Descrever"
                  autocomplete="off"
                  class="form-control"
                  v-model="procedure.clinic_requested_procedure"
                  :state="errors?.clinic_requested_procedure ? false : null"
                  ref="clinic_requested_procedure"
                />
                <b-form-invalid-feedback v-if="errors.clinic_requested_procedure">
                  {{ errors.clinic_requested_procedure }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col class="mt-3" cols="4">
              <b-form-group>
                <label for="supplementary_bed">Leito complementar</label>
                <b-form-input
                  placeholder="Descrever"
                  autocomplete="off"
                  class="form-control"
                  v-model="procedure.supplementary_bed"
                  :state="errors?.supplementary_bed ? false : null"
                  ref="supplementary_bed"
                />
                <b-form-invalid-feedback v-if="errors.supplementary_bed">
                  {{ errors.supplementary_bed }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col class="mt-3" cols="12">
              <b-form-group>
                <label for="equipment_required_hospitalization">Equipamentos requeridos para internação</label>
                <b-form-input
                  placeholder="Descrever"
                  autocomplete="off"
                  class="form-control"
                  v-model="procedure.equipment_required_hospitalization"
                  :state="errors?.equipment_required_hospitalization ? false : null"
                  ref="equipment_required_hospitalization"
                />
                <b-form-invalid-feedback v-if="errors.equipment_required_hospitalization">
                  {{ errors.equipment_required_hospitalization }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>            
          </b-row>

          <b-col cols="10">
            <b-form-group label="Procedimento">
              <ItemHealthPlanSimpleSelector 
                  :clinicHealthPlanId="clinicHealthPlanId"
                  :allowedTypes="['PROCEDURE','APPOINTMENT', 'EXAM', 'RETURN']"
                  v-model="procedure.item"
                  @select="setGuideItem"
                  :state="errors?.procedure_item ? false : null"
                  ref="procedure_item"
              />
              <b-form-invalid-feedback v-if="errors?.procedure_item">
                {{ errors.procedure_item }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Qtde.">
              <b-form-input
                v-model="procedure.quantity"
                placeholder="00"
                autocomplete="off"
                type="number"
                min="1"
                :state="errors?.quantity ? false : null"
                ref="quantity"
              />
              <b-form-invalid-feedback v-if="errors?.quantity">
                {{ errors.quantity }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Valor Unitário">
              <money 
                id="unitary_value"
                v-model="procedure.unitary_value" 
                class="form-control"
                :state="errors?.procedure_unitary_value ? false : null"
                ref="procedure_unitary_value"
              />
              <b-form-invalid-feedback v-if="errors?.procedure_unitary_value">
                {{ errors.procedure_unitary_value }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Valor Total">
              <money 
                id="unitary_value"
                v-model="procedure.total_value" 
                class="form-control"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
  
      <div class="wrapper-button text-center mt-3">
        <b-button variant="primary" size="lg" @click="saveProcedure" v-if="readonly">
          Salvar procedimento
        </b-button>
      </div>
    </b-modal>
  </template>
  
  <script>
  import moment from 'moment';

  export default {
    name: 'ProcedureAihModal',
    components: {
      Close: () => import('@/assets/icons/close.svg'),
      ChevronDown: () => import('@/assets/icons/chevron-down-select.svg'),
      ItemHealthPlanSimpleSelector: () => import('@items/components/ItemHealthPlanSimpleSelector'),
    },
    data() {
      return {
        professionalIndicatorOptions: [
            {
                code: 'cpf',
                label: 'CPF'
            },
            {
                code: 'cns',
                label: 'CNS'
            }
        ],
        teamIndicatorOptions: [
            {
                code: '0',
                label: '0-Não Aplicavel',
            },
            {
                code: '1',
                label: '1-Primeiro Cirurgião'
            },
            {
                code: '2',
                label: '2-Primeiro Auxiliar'
            },
            {
                code: '3',
                label: '3-Segundo Auxiliar',
            },
            {
                code: '4',
                label: '4-Terceiro Auxiliar'
            },
            {
                code: '5',
                label: '5-Quarto Auxiliar'
            },
            {
                code: '6',
                label: '6-Anestesista'
            }
        ],
        serviceProviderIndicatorOptions: [
            {
                code: '0',
                label: '0-Não Aplicavel',
            },
            {
                code: '3',
                label: '3- CNPJ'
            },
            {
                code: '5',
                label: '5- CNES'
            }
        ],
        executorProviderIndicatorOptions: [
            {
                code: '1',
                label: '1-CPF',
            },
            {
                code: '2',
                label: '2-CNS'
            },
            {
                code: '3',
                label: '3-CNPJ'
            },
            {
                code: '5',
                label: '5- CNES'
            }
        ],
        bedSpecialtyOptions: [
          {
            'code': '01',
            'label': '01-CIRURGICO',
          },
          {
            'code': '02',
            'label': '02-OBSTETRICOS',
          },
          {
            'code': '03',
            'label': '03-CLINICOS'
          },
          {
            'code': '04',
            'label': '04-CRONICOS'
          },
          {
            'code': '05',
            'label': '05-PSIQUIATRIA'
          },
          {
            'code': '06',
            'label': '06-PNEUMOLOGIA SANITARIA (TISIOLOGIA)'
          },
          {
            'code': '07',
            'label': '07-PEDIATRICOS'
          },
          {
            'code': '08',
            'label': '08-REABILITACAO'
          },
          {
            'code': '09',
            'label': '09-LEITO DIA - CIRURGICOS'
          },
          {
            'code': '10',
            'label': '10-LEITO DIA - AIDS'
          },
          {
            'code': '11',
            'label': '11-LEITO DIA - FIBROSE CISTICA'
          },
          {
            'code': '12',
            'label': '12-LEITO DIA - INTERCORRENCIA POS- TRANSP'
          },
          {
            'code': '13',
            'label': '13-LEITO DIA - GERIATRIA'
          },
          {
            'code': '14',
            'label': '14-LEITO DIA - SAUDE MENTAL'
          },
          {
            'code': '74',
            'label': '74-UTI I'
          },
          {
            'code': '75',
            'label': '75-UTI ADULTO II'
          },
          {
            'code': '76',
            'label': '76-UTI ADULTO III'
          },
          {
            'code': '77',
            'label': '77-UTI INFANTIL I'
          },
          {
            'code': '78',
            'label': '78-UTI INFANTIL II'
          },
          {
            'code': '79',
            'label': '79-UTI INFANTIL III'
          },
          {
            'code': '80',
            'label': '80-UTI NEONATAL I'
          },
          {
            'code': '81',
            'label': '81-UTI NEONATAL II'
          },
          {
            'code': '82',
            'label': '82-UTI NEONATAL III'
          },
          {
            'code': '83',
            'label': '83-UTI QUEIMADOS'
          },
          {
            'code': '84',
            'label': '84-ACOLHIMENTO NOTURNO'
          },
          {
            'code': '85',
            'label': '85-UTI CORONARIANA - UCO TIPO II'
          },
          {
            'code': '86',
            'label': '86-UTI CORONARIANA - UCO TIPO III'
          },
          {
            'code': '87',
            'label': '87-SAUDE MENTAL (CLINICOS)'
          },
          {
            'code': '88',
            'label': '88-QUEIMADO ADULTO (CLINICO)'
          },
          {
            'code': '89',
            'label': '89-QUEIMADO PEDIATRICO (CLINICO)'
          },
          {
            'code': '90',
            'label': '90-QUEIMADO ADULTO (CIRURGICO)'
          },
          {
            'code': '91',
            'label': '91-QUEIMADO PEDIATRICO'
          }
        ],
        changeProcedureOptions: [
          {
            'code': '1',
            'label': '1 - Sim'
          },
          {
            'code': '2',
            'label': '2 - Não'
          }                
        ],
        characterHospitalizationOptions: [
          {
            'code': '01',
            'label': '01 - Eletivo'
          },
          {
            'code': '02',
            'label': '02 - Urgência'
          },
          {
            'code': '99',
            'label': '99 - Sem registro'
          },
        ],
        exitStayReasonOptions:[],
        errors: {},
        selectedExitStayReason: null,
        selectedBedSpecialty: null,
        selectedChangeProcedure: null,
        selectedCharacterHospitalization: null,
        langDatePicker: {
          formatLocale: {
            months: moment.months(),
            monthsShort: moment.monthsShort(),
          },
        },
      }
    },
    props: {
      type: String,
      procedure: {
        type: Object,
        default: () => ({}),
      },
      readonly: Boolean,
      clinicHealthPlanId: String,
      requestFields: {},
    },
    async mounted (){
      await this.getExitStayReasons();      
    },
    methods: {
        getExitStayReasons() {
            if (this.exitStayReasonOptions.length === 0) {
                this.api.getExitStayReasons().then(async res => {
                this.exitStayReasonOptions = res.data.map(type => {
                    return {
                    label: `${type?.code} - ${type.description}`,
                        code: type.code,
                        id: type.id
                        }
                    })
                })
            }
        },
      setGuideItem(item) {
        this.procedure.item = item;
        this.procedure.code = item.code;
        this.procedure.item_id = item.id;
        this.procedure.name = item.name;
        this.procedure.quantity = 1;
        this.procedure.unitary_value = item.health_plan_props.value;
      },
      onShow() {     
      },
      closeModal() {
        this.selectedBedSpecialty =  null;
        this.selectedExitStayReason = null;
        this.selectedChangeProcedure =  null;
        this.selectedCharacterHospitalization = null;
        this.$bvModal.hide('procedure-aih-modal')
      },
      saveProcedure() {
        if (this.isValidForm()) {
          this.$toast.warning('Preencha todos os campos obrigatórios.')
          return
        }

        this.$emit('update-procedures', { 
          ...this.procedure,
          bed_specialty_code: this.selectedBedSpecialty?.code,
          exit_stay_reason_code: this.selectedExitStayReason?.code,
          change_procedure_code: this.selectedChangeProcedure?.code,
          character_hospitalization_code: this.selectedCharacterHospitalization?.code
        });
        
        this.closeModal();
      },
      isValidForm() {
        const errors = {};

        if(!this.selectedBedSpecialty){
          errors.bed_specialty = "Campo Obrigatório";
        }
        
        if(!this.procedure.date_admission){
          errors.date_admission = "Campo Obrigatório";
        }
        
        if(!this.procedure.exit_date){
          errors.exit_date = "Campo Obrigatório";
        }

        if(!this.selectedExitStayReason){
          errors.exit_stay_reason = "Campo Obrigatório";
        }

        if(!this.selectedChangeProcedure){
          errors.change_procedure = "Campo Obrigatório";
        }

        if(!this.procedure.character_hospitalization){
          // errors.character_hospitalization = "Campo Obrigatório";
        }

        if(!this.procedure.item){
          errors.procedure_item = "Campo Obrigatório";
        }

        if(!this.procedure.quantity){
          errors.procedure_quantity = "Campo Obrigatório";
        }

        Object.keys(this.requestFields).map(item => {
          if(this.$refs[item] && (!this.$refs[item].value || this.$refs[item].value.length === 0) && this.requestFields[item]){
            errors[item] = "Campo obrigatório";
          }
        });

        this.errors = errors;

        return Object.keys(errors).length;
      }
    },
    watch: {
      'procedure'() {
        if(this.procedure.bed_specialty_code){
          this.selectedBedSpecialty = this.bedSpecialtyOptions.find(item => item.code === this.procedure.bed_specialty_code);
        }

        if(this.procedure.exit_stay_reason_code){
          this.selectedExitStayReason = this.exitStayReasonOptions.find(item => item.code === this.procedure.exit_stay_reason_code);
        }
        
        if(this.procedure.change_procedure_code){
          this.selectedChangeProcedure = this.changeProcedureOptions.find(item => item.code === this.procedure.change_procedure_code);
        }
        
        if(this.procedure.character_hospitalization_code){
          this.selectedCharacterHospitalization = this.characterHospitalizationOptions.find(item => item.code === this.procedure.character_hospitalization_code);
        }

        if(this.procedure.character_hospitalization_code){
          this.selectedCharacterHospitalization = this.characterHospitalizationOptions.find(item => item.code === this.procedure.character_hospitalization_code);
        }
      },
      'procedure.quantity'() {
        this.procedure.total_value = this.procedure.unitary_value * this.procedure.quantity
      },
      'procedure.unitary_value'() {
        this.procedure.total_value = this.procedure.unitary_value * this.procedure.quantity
      },
    }
  };
  </script>
  <style lang="scss">
    #procedure-aih-modal {
      .form-title {
        color: var(--neutral-600);
        font-size: 18px;
        font-weight: bolder;
        line-height: 28px;
      }
      .modal-header {
        .modal-title {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
        }

        .icon-close {
          width: 25px;

          svg {
            fill: var(--neutral-500);
          }
        }
      }
      
      .col-form-label {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          color: #0a184c;
          margin: 0;
          margin-bottom: 0px;
      }

      .invalid-feedback{
        display: block;
      }
  }
  </style>