<template>
  <b-modal
    id="aih-sus-guide-modal"
    hide-header
    hide-footer
    centered
    size="xl"
    @show="onShow"
    @hidden="closeModal"
    no-close-on-backdrop
    no-close-on-esc 
  >
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="localSusGuide?.id">
          Atualizar guia AIH (Autorização de Internação Hospitalar) - N° {{ localSusGuide?.guide?.guide_number }}
        </span>
        <span v-else>
          Nova guia AIH (Autorização de Internação Hospitalar) - N° {{ newGuideNumber }}
        </span>
      </div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>

    <div class="around-content">
      <!-- Executante -->
      <b-row>
        <b-col class="mt-3" cols="12">
          <p class="form-title">Identificação do Estabelecimento de Saúde</p>
        </b-col>
        <b-col class="mt-3" cols="9">
          <b-form-group>
            <label for="requesting_establishment_name">Nome do estabelecimento solicitante</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              ref="requesting_establishment_name"
              v-model="form.requesting_establishment_name"
              :readonly="readonly"
              :state="errors.requesting_establishment_name ? false : null"
            />
            <b-form-invalid-feedback v-if="errors.requesting_establishment_name">
              {{ errors.requesting_establishment_name }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="requesting_establishment_cnes">CNES</label>
            <b-form-input
              placeholder="0000000"
              v-mask="'#######'"
              autocomplete="off"
              class="form-control"
              ref="requesting_establishment_cnes"
              v-model="form.requesting_establishment_cnes"
              :readonly="readonly"
              :state="errors.requesting_establishment_cnes ? false : null"
            />
            <b-form-invalid-feedback v-if="errors.requesting_establishment_cnes">
              {{ errors.requesting_establishment_cnes }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="7">
          <b-form-group>
            <label for="executing_establishment_name">Nome do estabelecimento executante</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              ref="executing_establishment_name"
              v-model="form.executing_establishment_name"
              :readonly="readonly"
              :state="errors.executing_establishment_name ? false : null"
            />
            <b-form-invalid-feedback v-if="errors.executing_establishment_name">
              {{ errors.executing_establishment_name }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="executing_establishment_ibge_code">Cód. município</label>
            <b-form-input
              placeholder="0000000000"
              v-mask="'##########'"
              autocomplete="off"
              class="form-control"
              ref="executing_establishment_ibge_code"
              v-model="form.executing_establishment_ibge_code"
              :readonly="readonly"
              :state="errors.executing_establishment_ibge_code ? false : null"
            />
            <b-form-invalid-feedback v-if="errors.executing_establishment_ibge_code">
              {{ errors.executing_establishment_ibge_code }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="executing_establishment_cnes">CNES</label>
            <b-form-input
              placeholder="000000"
              v-mask="'#######'"
              autocomplete="off"
              class="form-control"
              ref="executing_establishment_cnes"
              v-model="form.executing_establishment_cnes"
              :readonly="readonly"
              :state="errors.executing_establishment_cnes ? false : null"
            />
            <b-form-invalid-feedback v-if="errors.executing_establishment_cnes">
              {{ errors.executing_establishment_cnes }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row> 

      <!-- Informações da AIH -->
      <b-row>
        <b-col class="mt-3" cols="12">
          <p class="form-title">Informações da AIH</p>
        </b-col>
        <b-col class="mt-3" cols="4">
          <b-form-group>
            <label for="identification">Identificação AIH</label>
            <multiselect
              id="identification"
              autocomplete="off"
              ref="identification"
              v-model="selectedIdentification"
              @input="changeSelectedIdentification"
              :options="identificationOptions"
              track-by="code"
              label="label"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <div 
              v-if="errors.identification ? true : false"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="aih_number">
              Número da AIH
            </label>
            <b-form-input
              id="aih_number"
              v-mask="'##################'"
              placeholder="000000000000000000"
              autocomplete="off"
              class="form-control"
              ref="aih_number"
              v-model="form.aih_number"
              :disabled="readonly"
              maxlength="13"
              :state="errors?.aih_number ? false : null"
            />
            <b-form-invalid-feedback v-if="errors?.aih_number">
              {{ errors.aih_number }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="4">
          <b-form-group>
            <label for="modality">Modalidade da AIH</label>
            <multiselect
              id="aih_modality"
              autocomplete="off"
              ref="modality"
              v-model="selectedSusAihModality"
              @input="changeSelectedSusAihModality"
              :options="modalityOptions"
              track-by="code"
              label="label"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <div 
              v-if="errors.modality ? true : false"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="competency">Competência</label>
            <date-picker
              placeholder="Selecionar"
              v-model="form.competency"
              name="competency"
              format="MM/YYYY"
              value-type="date"
              id="competency"
              :lang="langDatePicker"
              :clearable="false"
              class="full"
              :state="errors?.competency ? false : null"
              :disabled="readonly || !!localSusGuide?.sus_batch_id"
              :default-value="new Date()"
              ref="competency"
            />
            <b-form-invalid-feedback v-if="errors?.competency">
              {{ errors.competency }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>   

      <!-- Diretor do estabelecimento executante -->
      <b-row>
        <b-col class="mt-3" cols="12">
          <p class="form-title">Diretor do estabelecimento executante</p>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="name_director_establishment">Nome do Diretor Clínico do estabelecimento</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.name_director_establishment"
              :readonly="readonly"
              ref="name_director_establishment"
              :state="errors.name_director_establishment ? false : null"
            />
            <b-form-invalid-feedback v-if="errors.name_director_establishment">
              {{ errors.name_director_establishment }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="cpf_director_establishment">CPF do Diretor</label>
            <b-form-input
              placeholder="000.000.000-00"
              v-mask="'###.###.###-##'"
              autocomplete="off"
              class="form-control"
              v-model="form.cpf_director_establishment"
              :readonly="readonly"
              ref="cpf_director_establishment"
              :state="errors.cpf_director_establishment ? false : null"
            />
            <b-form-invalid-feedback v-if="errors.cpf_director_establishment">
              {{ errors.cpf_director_establishment }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="director_council_number">N° Cons. de Classe</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.director_council_number"
              :readonly="readonly"
              ref="director_council_number"
              :state="errors.director_council_number ? false : null"
            />
            <b-form-invalid-feedback v-if="errors.director_council_number">
              {{ errors.director_council_number }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="executing_doctor_name">Nome do Médico responsável</label>
            <ProfessionalFieldModule
              :clinic="clinic"
              :professional="form?.executing_doctor"
              :readonly="readonly"
              @update-professional="updateProfessional"
            />
            <b-form-invalid-feedback v-if="errors.executing_doctor_name">
              {{ errors.executing_doctor_name }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="indicator_executing_doctor">Indicador do profissional</label>
            <multiselect
              id="indicator_executing_doctor"
              autocomplete="off"
              v-model="form.indicator_executing_doctor"
              :options="indicatorProfessionalOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              placeholder="Selecionar"
              class="with-border"
              :disabled="true"
              ref="indicator_executing_doctor"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback v-if="errors.indicator_executing_doctor">
              {{ errors.indicator_executing_doctor }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="number_executing_doctor">Número do documento</label>
            <b-form-input
              placeholder="000000000000000"
              v-mask="getMaskExecutingDoctor"
              autocomplete="off"
              class="form-control"
              v-model="form.number_executing_doctor"
              :readonly="true"
              ref="number_executing_doctor"
              :state="errors.number_executing_doctor ? false : null"
            />
            <b-form-invalid-feedback v-if="errors.number_executing_doctor">
              {{ errors.number_executing_doctor }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Paciente -->
      <b-row>
        <b-col cols="12">
          <p class="form-title">Identificação do Paciente</p>
        </b-col>  
        <b-col class="mt-3" cols="7">
          <b-form-group>
            <label for="patient_name">Nome do paciente</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.name"
              :readonly="readonly"
              :state="errors.patient_name ? false : null"
              ref="patient_name"
            />
            <b-form-invalid-feedback v-if="errors.patient_name">
              {{ errors.patient_name }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_cpf">CPF do paciente</label>
            <b-form-input
              placeholder="000.000.000-00"
              v-mask="'###.###.###-##'"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.cpf"
              :readonly="readonly"
              :state="errors.patient_cpf ? false : null"
              ref="patient_cpf"
            />
            <b-form-invalid-feedback v-if="errors.patient_cpf">
              {{ errors.patient_cpf }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="patient_cns">Cartão Nacional de Saúde (CNS)</label>
            <b-form-input
              placeholder="000-0000-0000-0000"
              v-mask="'###-####-####-####'"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.cns"
              :readonly="readonly"
              ref="patient_cns"
              />
              <b-form-invalid-feedback v-if="errors.patient_cns">
                {{ errors.patient_cns }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_birthday">Data de nascimento</label>
            <b-form-input
              v-model="form.patient.birthday"
              id="patient_birthday"
              name="patient_birthday"
              class="full"
              type="date"
              :readonly="readonly"
              :state="errors.patient_birthday ? false : null"
              ref="patient_birthday"
            />
            <b-form-invalid-feedback v-if="errors.patient_birthday">
              {{ errors.patient_birthday }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>    
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="patient_nationality">Nacionalidade</label>
              <multiselect
                id="nationality"
                autocomplete="off"
                v-model="selectedNationality"
                @input="changeSelectedNationality"
                :options="nationalities"
                track-by="id"
                label="label"
                :option-height="40"
                :showLabels="false"
                :searchable="true"
                placeholder="Selecione um país"
                class="with-border"
                :disabled="readonly"
                ref="patient_nationality"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="noOptions"> Nenhuma opção </template>
                <template slot="noResult"> Nenhum resultado </template>
              </multiselect>
              <b-form-invalid-feedback v-if="errors?.patient_nationality">
                {{ errors.patient_nationality }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_gender">Sexo</label>
            <b-form-radio-group
              id="patient_gender"
              v-model="form.patient.gender"
              name="patient_gender"
              class="pt-2"
              :disabled="readonly"
              :state="errors.patient_gender ? false : null"
            >
              <b-form-radio value="M">Masc.</b-form-radio>
              <b-form-radio value="F">Fem.</b-form-radio>
            </b-form-radio-group>
            <b-form-invalid-feedback v-if="errors.patient_gender">
              {{ errors.patient_gender }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>        
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_ethnicity">
              Raça/Cor
              <span id="help-icon-ancestry" class="icon-box help-icon" v-b-tooltip.hover title="Quando o campo “Raça/cor” for selecionado “Indígena” o campo “Etnia” será obrigatório.">
                <HelpCircle class="icon stroke" />
              </span>
            </label>
            <multiselect
              id="ethinicity"
              v-model="selectedEthnicity"
              :options="ethnicityOptions"
              @input="changeSelectedEthnicity"
              track-by="id"
              label="label"
              :option-height="40"
              :showLabels="false"
              :searchable="false"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
              ref="patient_ethnicity"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback :state="errors.patient_ethnicity ? false : null">
              {{ errors.patient_ethnicity }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="patient_ancestry">
              Etnia
            </label>
            <multiselect
              id="ancestry"
              v-model="selectedAncestry"
              @input="changeSelectedAncestry"
              track-by="id"
              label="label"
              :options="ancestryOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="false"
              placeholder="Selecionar"
              class="with-border"
              :disabled="selectedEthnicity?.code !== '05' || readonly"
              ref="patient_ancestry"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback :state="errors.patient_ancestry ? false : null">
              {{ errors.patient_ancestry }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="9">
          <b-form-group>
            <label for="patient_mother_name">Nome da mãe</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.mother_name"
              :readonly="readonly"
              :state="errors.patient_mother_name ? false : null"
              ref="patient_mother_name"
            />
            <b-form-invalid-feedback v-if="errors.patient_mother_name">
              {{ errors.patient_mother_name }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="patient_mother_phone">Telefone de contato</label>
            <b-form-input
              placeholder="(00) 00000-0000"
              v-mask="'(##) ####-####'"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.telephone_mother"
              :readonly="readonly"
              :state="errors.patient_mother_phone ? false : null"
              ref="patient_mother_phone"
              />
              <b-form-invalid-feedback v-if="errors.patient_mother_phone">
                {{ errors.patient_mother_phone }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="9">
          <b-form-group>
            <label for="patient_responsible_name">Nome do responsável</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.name_responsible"
              :readonly="readonly"
              :state="errors.patient_responsible_name ? false : null"
              ref="patient_responsible_name"
              />
              <b-form-invalid-feedback v-if="errors.patient_responsible_name">
                {{ errors.patient_responsible_name }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="patient_responsible_phone">Telefone de contato</label>
            <b-form-input
              v-mask="'(##) ####-####'"
              placeholder="(00) 00000-0000"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.telephone_responsible"
              :readonly="readonly"
              :state="errors.telephone_responsible ? false : null"
              ref="patient_responsible_phone"
              />
            <b-form-invalid-feedback v-if="errors.patient_responsible_phone">
              {{ errors.patient_responsible_phone }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_address_zipcode">CEP</label>
            <b-form-input
              v-mask="'#####-###'"
              placeholder="00000-000"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.zipCode"
              :readonly="readonly"
              :state="errors?.patient_address_zipcode ? false : null"
              @keyup="searchCEP"
              ref="patient_address_zipcode"
              />
            <b-form-invalid-feedback v-if="errors?.patient_address_zipcode">
              {{ errors.patient_address_zipcode }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_address_ibge_code">Cód. IBGE Município</label>
            <b-form-input
              placeholder="0000000"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.ibge_code"
              :readonly="readonly"
              v-mask="'#######'"
              :state="errors?.patient_address_ibge_code ? false : null"
              ref="patient_address_ibge_code"
              />
            <b-form-invalid-feedback v-if="errors?.patient_address_ibge_code">
              {{ errors.patient_address_ibge_code }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_street_type">Tipo Logradouro</label>
            <multiselect
              id="patient_street_type"
              v-model="selectedStreetType"
              @input="changeSelectedStreetType"
              track-by="code"
              label="label"
              :options="streetTypeOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :allowEmpty="false"
              placeholder="Selecione"
              class="with-border"
              :disabled="readonly"
              ref="patient_street_type"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback v-if="errors?.patient_street_type">
              {{ errors.patient_street_type }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="patient_address">Logradouro</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.address"
              :readonly="readonly"
              :state="errors?.patient_address ? false : null"
              ref="patient_address"
              />
              <b-form-invalid-feedback v-if="errors?.patient_address">
                {{ errors.patient_address }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_address_number">Número</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              ref="patient_address_number"
              class="form-control"
              v-model="form.patient.address.number"
              :readonly="readonly"
              :state="errors?.patient_address_number ? false : null"
              />
              <b-form-invalid-feedback v-if="errors?.patient_address_number">
                {{ errors.patient_address_number }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="5">
          <b-form-group>
            <label for="patient_address_complement">Complemento</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.complement"
              ref="patient_address_complement"
              :readonly="readonly"
            />
            <b-form-invalid-feedback v-if="errors?.patient_address_complement">
                {{ errors.patient_address_complement }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="5">
          <b-form-group>
            <label for="patient_address_neighborhood">Bairro</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.neighborhood"
              :readonly="readonly"
              :state="errors?.patient_address_neighborhood ? false : null"
              ref="patient_address_neighborhood"
              />
              <b-form-invalid-feedback v-if="errors?.patient_address_neighborhood">
                {{ errors.patient_address_neighborhood }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="patient_address_city">Município</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.city"
              :readonly="readonly"
              :state="errors?.patient_address_city ? false : null"
              ref="patient_address_city"
              />
            <b-form-invalid-feedback v-if="errors?.patient_address_city">
              {{ errors.patient_address_city }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="patient_address_state">Estado</label>
            <multiselect
              id="addressState"
              v-model="selectedAddressState"
              @input="changeSelectedAddressState"
              track-by="id"
              label="label"
              :options="statesOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :allowEmpty="false"
              placeholder="UF"
              class="with-border"
              :disabled="readonly"
              ref="patient_address_state"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback v-if="errors?.patient_address_state">
              {{ errors.patient_address_state }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-col cols="12">
        <hr class="hr"/>
      </b-col>

      <!-- Justificativa -->
      <b-row>
        <b-col cols="12">
          <p class="form-title">Justificativa da Internação</p>
        </b-col>
        <b-col class="mt-3" cols="12">
          <b-form-group>
            <label for="main_clinical_features">Principais sinais e sintomas clínicos</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.main_clinical_features"
              :readonly="readonly"
              :state="errors?.main_clinical_features ? false : null"
              ref="main_clinical_features"
            />
            <b-form-invalid-feedback v-if="errors.main_clinical_features">
              {{ errors.main_clinical_features }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="12">
          <b-form-group>
            <label for="conditions_justifying_admission">Condições que justificam a internação</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.conditions_justifying_admission"
              :readonly="readonly"
              :state="errors?.conditions_justifying_admission ? false : null"
              ref="conditions_justifying_admission"
            />
            <b-form-invalid-feedback v-if="errors?.conditions_justifying_admission">
              {{ errors.conditions_justifying_admission }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="12">
          <b-form-group>
            <label for="main_results_diagnostic_tests">Principais resultados de provas diagnósticas (Resultado de Exames Realizados)</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.main_results_diagnostic_tests"
              :readonly="readonly"
              :state="errors?.main_results_diagnostic_tests ? false : null"
              ref="main_results_diagnostic_tests"
            />
            <b-form-invalid-feedback v-if="errors?.main_results_diagnostic_tests">
              {{ errors.main_results_diagnostic_tests }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="initial_diagnosis">Diagnóstico inicial</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.initial_diagnosis"
              :readonly="readonly"
              :state="errors?.initial_diagnosis ? false : null"
              ref="initial_diagnosis"
            />
            <b-form-invalid-feedback v-if="errors.initial_diagnosis">
              {{ errors.initial_diagnosis }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="request_id">CID 10 Principal</label> 
            <multiselect
              v-model="form.cid_1"
              id="cid1"
              track-by="id"
              label="label"
              :options="cid10"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :internal-search="false"
              @search-change="searchCid10"
              @select="selectCid_1"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
              ref="cid_main_aih"
            >
            <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"
                >Digite para pesquisar um CID10
              </template>
              <template slot="noResult">Nenhum CID10 encontrado</template>
            </multiselect>
            <b-form-invalid-feedback :state="errors?.cid_main_aih ? false : null">
              {{ errors.cid_main_aih }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="cid2">CID 10 secundário</label>
            <multiselect
              v-model="form.cid_2"
              id="cid2"
              track-by="id"
              label="label"
              :options="cid10_sec"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :internal-search="false"
              @search-change="searchCid10Sec"
              @select="selectCid_2"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
              ref="cid_2"
            >
            <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"
                >Digite para pesquisar um CID10
              </template>
              <template slot="noResult">Nenhum CID10 encontrado</template>
            </multiselect>
            <b-form-invalid-feedback :state="errors?.cid_2 ? false : null">
              {{ errors.cid_2 }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="cid10_associated_causes">CID 10 causas associados</label>
            <multiselect
              v-model="form.cid_associate"
              id="cid1"
              track-by="id"
              label="label"
              :options="cid10_associated_causes"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :internal-search="false"
              @search-change="searchAssociatedCauses"
              @select="selectAssociatedCauses"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
              ref="cid10_associated_causes"
            >
            <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"
                >Digite para pesquisar um CID10
              </template>
              <template slot="noResult">Nenhum CID10 encontrado</template>
            </multiselect>
            <b-form-invalid-feedback :state="errors?.cid10_associated_causes ? false : null">
              {{ errors.cid10_associated_causes }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="compulsory_reporting">N° notific. compulsória</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.compulsory_reporting"
              :readonly="readonly"
              :state="errors?.compulsory_reporting ? false : null"
              ref="compulsory_reporting"
            />
            <b-form-invalid-feedback v-if="errors.compulsory_reporting">
              {{ errors.compulsory_reporting }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>        
      </b-row>

      <AihProcedure
        :checkedItems="procedures"
        :readonly="!readonly"
        :clinicHealthPlanId="susData?.clinic_health_plan?.id"
        @update-procedures="updateProcedures"
        :requestFields="requiredField"
        :type="'aih'"
        :isValidCPF="isValidCPF"
        :isValidCNPJ="isValidCNPJ"
      />

      <AihSecondaryProcedure
        :checkedItems="secondary_procedures"
        :readonly="!readonly"
        :clinicHealthPlanId="susData?.clinic_health_plan?.id"
        @update-secondary-procedures="updateSecondaryProcedures"
        :requestFields="requiredField"
        :type="'aih'"
        :isValidCPF="isValidCPF"
        :isValidCNPJ="isValidCNPJ"
      />
      
      <AihOpmProcedure
        :checkedItems="opm_procedures"
        :readonly="!readonly"
        :clinicHealthPlanId="susData?.clinic_health_plan?.id"
        @update-opm-procedures="updateOpmProcedures"
        :requestFields="requiredField"
        :type="'aih'"
        :isValidCPF="isValidCPF"
        :isValidCNPJ="isValidCNPJ"
      />

      <!-- Profissional solicitante -->
      <b-row>
        <b-col class="mt-3" cols="12">
          <p class="form-title">Profissional Solicitante</p>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="name_requesting_professional">Nome do profissional solicitante</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.name_requesting_professional"
              :readonly="readonly"
              :state="errors.name_requesting_professional ? false : null"
              ref="name_requesting_professional"
            />
            <b-form-invalid-feedback v-if="errors.name_requesting_professional">
              {{ errors.name_requesting_professional }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="indicator_requesting_professional">Indicador do profissional</label>
            <multiselect
              id="indicator_requesting_professional"
              autocomplete="off"
              v-model="form.indicator_requesting_professional"
              :options="indicatorProfessionalOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
              ref="indicator_requesting_professional"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback v-if="errors.indicator_requesting_professional">
              {{ errors.indicator_requesting_professional }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="document_requesting_professional">N° do documento do profissional</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-mask="getMaskRequestingProfessional"
              v-model="form.document_requesting_professional"
              :readonly="readonly"
              :state="errors.document_requesting_professional ? false : null"
              ref="document_requesting_professional"
            />
            <b-form-invalid-feedback v-if="errors.document_requesting_professional">
              {{ errors.document_requesting_professional }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="requesting_council_number">N° Cons. de Classe</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.requesting_council_number"
              :readonly="readonly"
              :state="errors.requesting_council_number ? false : null"
              ref="requesting_council_number"
            />
            <b-form-invalid-feedback v-if="errors.requesting_council_number">
              {{ errors.requesting_council_number }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="date_request">Data da solicitação</label>
            <date-picker
              placeholder="Selecionar"
              v-model="form.date_request"
              name="date_request"
              format="DD/MM/YYYY"
              value-type="date"
              id="date_request"
              :lang="langDatePicker"
              :clearable="false"
              class="full"
              :state="errors?.date_request ? false : null"
              :disabled="readonly"
              ref="date_request"
            />
            <b-form-invalid-feedback v-if="errors?.date_request">
              {{ errors.date_request }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="desired_date_admission">Data desejada p/ internação</label>
            <date-picker
              placeholder="Selecionar"
              v-model="form.desired_date_admission"
              name="desired_date_admission"
              format="DD/MM/YYYY"
              value-type="date"
              id="desired_date_admission"
              :lang="langDatePicker"
              :clearable="false"
              class="full"
              :state="errors?.desired_date_admission ? false : null"
              :disabled="readonly"
              ref="desired_date_admission"
            />
            <b-form-invalid-feedback v-if="errors?.desired_date_admission">
              {{ errors.desired_date_admission }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="mt-3" cols="12">
          <p class="form-title">Acidentes ou violências (Preencher em causas externas)</p>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="type_accident">Tipo de acidente</label>
            <multiselect
              id="type_accident"
              autocomplete="off"
              v-model="form.type_accident_code"
              :options="typeAccidentOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
              ref="type_accident"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback v-if="errors.type_accident">
              {{ errors.type_accident }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="insurance_company_cnpj">CNPJ da seguradora</label>
            <b-form-input
              placeholder="Descrever"
              v-mask="'##.###.###/####-##'"
              autocomplete="off"
              class="form-control"
              v-model="form.insurance_company_cnpj"
              :readonly="readonly"
              :state="errors.insurance_company_cnpj ? false : null"
              ref="insurance_company_cnpj"
            />
            <b-form-invalid-feedback v-if="errors.insurance_company_cnpj">
              {{ errors.insurance_company_cnpj }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="ticket_number">N° do bilhete</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.ticket_number"
              :readonly="readonly"
              :state="errors.ticket_number ? false : null"
              ref="ticket_number"
            />
            <b-form-invalid-feedback v-if="errors.ticket_number">
              {{ errors.ticket_number }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="serie">Série</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.serie"
              :readonly="readonly"
              :state="errors.serie ? false : null"
              ref="serie"
            />
            <b-form-invalid-feedback v-if="errors.serie">
              {{ errors.serie }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="link_social_security">Vínculo com a previdência</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.link_social_security"
              :readonly="readonly"
              :state="errors.link_social_security ? false : null"
              ref="link_social_security"
            />
            <b-form-invalid-feedback v-if="errors.link_social_security">
              {{ errors.link_social_security }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="company_cnpj">CNPJ da empresa</label>
            <b-form-input
              placeholder="Descrever"
              v-mask="'##.###.###/####-##'"
              autocomplete="off"
              class="form-control"
              v-model="form.company_cnpj"
              :readonly="readonly"
              :state="errors.company_cnpj ? false : null"
              ref="company_cnpj"
            />
            <b-form-invalid-feedback v-if="errors.company_cnpj">
              {{ errors.company_cnpj }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="cnae_company">CNAE da Empresa</label>
            <b-form-input
              placeholder="000"
              autocomplete="off"
              class="form-control"
              v-mask="'###'"
              v-model="form.cnae_company"
              :readonly="readonly"
              :state="errors.cnae_company ? false : null"
              ref="cnae_company"
            />
            <b-form-invalid-feedback v-if="errors.cnae_company">
              {{ errors.cnae_company }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="cbor_company">CBOR</label>
            <b-form-input
              placeholder="000000"
              autocomplete="off"
              class="form-control"
              v-mask="'######'"
              v-model="form.cbor_company"
              :readonly="readonly"
              :state="errors.cbor_company ? false : null"
              ref="cbor_company"
            />
            <b-form-invalid-feedback v-if="errors.cbor_company">
              {{ errors.cbor_company }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="mt-3" cols="12">
          <p class="form-title">Autorização</p>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="authorizing_establishment_name">Nome do profissional autorizador</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.authorizing_establishment_name"
              :readonly="readonly"
              :state="errors.authorizing_establishment_name ? false : null"
              ref="authorizing_establishment_name"
            />
            <b-form-invalid-feedback v-if="errors.authorizing_establishment_name">
              {{ errors.authorizing_establishment_name }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="indicator_authorizing_professional">Indicador do profissional</label>
            <multiselect
              id="indicator_authorizing_professional"
              autocomplete="off"
              v-model="form.indicator_authorizing_professional"
              :options="indicatorProfessionalOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
              ref="indicator_authorizing_professional"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback v-if="errors.indicator_authorizing_professional">
              {{ errors.indicator_authorizing_professional }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="document_authorizing_professional">N° do documento do profissional</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-mask="getMaskAuthorizingProfessional"
              v-model="form.document_authorizing_professional"
              :readonly="readonly"
              :state="errors.document_authorizing_professional ? false : null"
              ref="document_authorizing_professional"
            />
            <b-form-invalid-feedback v-if="errors.document_authorizing_professional">
              {{ errors.document_authorizing_professional }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="authorization_date">Data da autorização</label>
            <date-picker
              placeholder="Selecionar"
              v-model="form.authorization_date"
              name="authorization_date"
              format="DD/MM/YYYY"
              value-type="date"
              id="authorization_date"
              :lang="langDatePicker"
              :clearable="false"
              class="full"
              :state="errors?.authorization_date ? false : null"
              :disabled="readonly"
              :default-value="new Date()"
              ref="authorization_date"
            />
            <b-form-invalid-feedback v-if="errors.authorization_date">
              {{ errors.authorization_date }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="issuing_body_code">Cód. Orgão Emissor</label>
            <b-form-input
              v-mask="'##########'"
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.issuing_body_code"
              :readonly="readonly"
              :state="errors?.issuing_body_code ? false : null"
              ref="issuing_body_code"
            />
            <b-form-invalid-feedback v-if="errors?.issuing_body_code">
              {{ errors.issuing_body_code }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="authorizing_council_number">N° Cons. de Classe</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.authorizing_council_number"
              :readonly="readonly"
              :state="errors.authorizing_council_number ? false : null"
              ref="authorizing_council_number"
            />
            <b-form-invalid-feedback v-if="errors.authorizing_council_number">
              {{ errors.authorizing_council_number }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <div class="wrapper-button">
        <b-button
          class="wh-button mr-2"
          variant="outline-primary"
          outline
          v-if="localSusGuide?.id"
          @click="print"
        >
          Imprimir guia
        </b-button>

        <b-button
          variant="primary"
          size="lg"
          @click="updatesusGuide"
          :disabled="localSusGuide.situation === 'Cancelada'"
          v-if="localSusGuide && localSusGuide.id && !readonly"
        >
          Atualizar guia
        </b-button>
        <b-button
          variant="primary"
          size="lg"
          @click="createGuide"
          v-else-if="!readonly"
        >
          Salvar guia
        </b-button>
      </div>

    </div>
  </b-modal>
</template>

<script>
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
import AihProcedure from './Modules/AihProcedure.vue';
import AihSecondaryProcedure from './Modules/AihSecondaryProcedure.vue';
import AihOpmProcedure from './Modules/AihOpmProcedure.vue';
import { SusBatch } from '@/modules/sus/manageGuides/utils/statuses'
import { saveAs } from 'file-saver'
import api from '@/modules/sus/api'
import moment from 'moment';
import axios from 'axios';

export default {
  components: {
    HelpCircle: () => import('@/assets/icons/help-circle.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down-select.svg'),
    ProfessionalFieldModule: () => import('./Modules/ProfessionalFieldModule'),
    AihProcedure,
    AihSecondaryProcedure,
    AihOpmProcedure
  },
  props: {
    sus_information_setting: Object,
    patient: {
      type: [Object, null]
    },
    clinicHealthPlan: {
      type: Object
    },
    susGuide: {
      type: Object,
      default: () => ({})
    },
    checkedItems: Array,
    appointmentId: String,
    clinicHealthPlanId: String
  },
  computed: {
    getMaskExecutingDoctor() {
      return this.form.indicator_executing_doctor === 'CPF' ? '###.###.###-##' : '###############';
    },
    getMaskRequestingProfessional() {
      return this.form.indicator_requesting_professional === 'CPF' ? '###.###.###-##' : '###############';
    },
    getMaskAuthorizingProfessional() {
      return this.form.indicator_authorizing_professional === 'CPF' ? '###.###.###-##' : '###############';
    }
  },
  data() {
    return {
      newGuideNumber: 0,
      isShow: false,
      localPatient: { ...this.patient },
      localSusGuide:  { ...this.susGuide },
      user: getCurrentUser(),
      isLoading: true,
      clinic: getCurrentClinic(),
      langDatePicker: {
        formatLocale: {
          months: moment.months(),
          monthsShort: moment.monthsShort(),
        },
      },
      errors: {},
      readonly: false,
      requiredField: {},
      susData: {},
      procedures: [],
      opm_procedures: [],
      secondary_procedures: [],
      form: this.getDefaultForm(),
      selectedIdentification: null,
      selectedSusAihModality: null,
      selectedNationality: null,
      selectedEthnicity: null,
      selectedAncestry: null,
      selectedAddressState: null,
      selectedExitStayReasons: null,
      selectedPatientDocumentType: null,
      selectedTypeAccident: null,
      selectedStreetType: null,
      identificationOptions: [
        {
          'code' : '01',
          'label': '01 - AIH Principal'
        },
        {
          'code' : '03',
          'label': '03 - AIH de Continuação',
        },
        {
          'code' : '05',
          'label': '05- AIH Longa Permanência'
        }
      ],
      modalityOptions: [
        {
          'code' : '02',
          'label': '02- Hospitalar',
        },
        {
          'code' : '03',
          'label': '03- Hospital Dia'
        },
        {
          'code' : '04',
          'label': '04- Internação Domiciliar'
        }
      ],
      patientDocumentTypeOptions: [
        {
          'code': '1',
          'label': '1- PIS-PASEP',
        },
        {
          'code': '2',
          'label': '2- Identidade (RG)',
        },
        {
          'code': '3',
          'label': '3- Reg. Civil'
        },
        {
          'code': '5',
          'label': '5- Ignorado'
        },
        {
          'code': '6',
          'label': '6- Matricula Certidão de Nasc.'
        }
      ],
      typeAccidentOptions: [
        'Acid. de Transito',
        'Acid. Trabalho/Tipico',
        'Acid. Trabalho/Trajeto',
      ],
      indicatorProfessionalOptions: [
        'CPF',
        'CNS'
      ],
      streetTypeOptions: [
        { code: '008', label: '008 - Avenida (AV)' },
        { code: '081', label: '081 - Rua (R)' },
        { code: '004', label: '004 - Alameda (AL)' },
        { code: '100', label: '100 - Travessa (TR)' },
        { code: '065', label: '065 - Praça (PÇA)' },
        { code: '031', label: '031 - Estrada (EST)' },
        { code: '090', label: '090 - Rodovia (ROD)' },
        { code: '054', label: '054 - Largo (LGO)' },
        { code: '104', label: '104 - Vila (VL)' },
        { code: '011', label: '011 - Beco (BCO)' },
      ],
      nationalities: [],
      ethnicityOptions: [],
      ancestryOptions: [],
      statesOptions: [],
      cid10: [],
      cid10_sec: [],
      cid10_associated_causes: [],
      exitStayReasonOptions: [],
    };
  },
  async mounted() {
    try {
      const { data } = await api.getInformationSusSettingByClinicId(this.clinic.id);
      
      this.api.showClinic(this.clinic.id).then(async res => {
        const resData = res.data;
        this.clinic.address = resData.address;
      });

      this.susData = data;

      await this.getRequiredFields();
      this.getAncestries();
      this.getEthnicities();
      this.getNationalities();
      this.getStates();
      this.getExitStayReasons();

    } catch (error) {
      console.error('Erro ao carregar dados do SUS', error);
    }
  },
  methods: {
    isReadonly() {
      this.readonly = false;
      
      if(this.localSusGuide?.id){
        this.readonly = Boolean(
          this.localSusGuide?.id &&
          (this.localSusGuide.sus_batch !== null && ( this.localSusGuide?.sus_batch?.status === SusBatch.BATCH_STATUS_FINISHED) )
        ); 
      }    
    },
    formatCid(cid) {
      return cid 
        ? { label: `${cid.code} - ${cid.description}`, value: cid.id } 
        : null;
    },
    formatDate(date) {
      return date ? moment(date).toDate() : null;
    },
    getCheckedItems(){
      if(this.checkedItems){
        this.procedures = this.checkedItems.slice(0,1).map((item, index) => ({
          id: null,
          item: item.item,
          item_id: item.item_id,
          code: item.item.code,
          name: item.item.name,
          attendance_date: moment(item?.appointment?.start_datetime).toDate(),
          quantity: 1,
          appointment_item_id: item.id,
          unitary_value: item.item.health_plan_props.value,
          total_value: item.item.health_plan_props.value,
          is_principal: index === 0
        }));

        this.form.procedures = this.procedures;

        this.secondary_procedures = this.checkedItems.slice(1).map((item, index) => ({
          id: null,
          item: item.item,
          item_id: item.item_id,
          code: item.item.code,
          name: item.item.name,
          attendance_date: moment(item?.appointment?.start_datetime).toDate(),
          quantity: 1,
          appointment_item_id: item.id,
          unitary_value: item.item.health_plan_props.value,
          total_value: item.item.health_plan_props.value,
          is_principal: index === 0
        }));

        this.form.secondary_procedures = this.secondary_procedures;
      }
    },
    getDefaultForm() {
      return {
        clinic: {
          name: '',
          address: '',
        },
        procedures: [],
        opm_procedures: [],
        secondary_procedures: [],
        patient: {
          cns: null,
          name: null,
          gender: null,
          birthday: null,
          ethnicity: null,
          ancestry: null,
          cellphone: null,
          email: '',
          address: {
            zipCode: '',
            ibge_code: null,
            street_code: null,
            address: '',
            complement: '',
            neighborhood: ''
          }
        },
        requesting_establishment_name: null,
        requesting_establishment_cnes: null,
        executing_establishment_name: null,
        executing_establishment_ibge_code: null,
        executing_establishment_cnes: null,
        identification_code: null,
        aih_number: null,
        modality_code: null,
        competency: null,
        executing_doctor_name: null,
        number_executing_doctor: null,
        patient_id: null,
        main_clinical_features: null,
        conditions_justifying_admission: null,
        main_results_diagnostic_tests: null,
        initial_diagnosis: null,
        cid_1: null,
        cid_2: null,
        cid_associate: null,
        compulsory_reporting: null,
        bed_specialty_code: null,
        exit_stay_reasons_code: null,
        patient_document_type_code: null,
        change_procedure_code: null,
        clinic_requested_procedure: null,
        name_director_establishment: null,
        cpf_director_establishment: null,
        director_council_number: null,
        indicator_executing_doctor: null,
        name_requesting_professional: null,
        indicator_requesting_professional: null,
        document_requesting_professional: null,
        requesting_council_number: null,
        date_request: null,
        desired_date_admission: null,
        insurance_company_cnpj: null,
        ticket_number: null,
        serie: null,
        link_social_security: null,
        company_cnpj: null,
        cnae_company: null,
        cbor_company: null,
        authorizing_establishment_name: null,
        indicator_authorizing_professional: null,
        document_authorizing_professional: null,
        issuing_body_code: null,
        authorizing_council_number: null,
        type_accident_code: null,
        authorization_date: null,
      };
    },
    async getRequiredFields() {
      try {
        const requiredfield = {};
        if (this.clinic.id) {
          const { data } = await api.getSusGuideRequiredFields(this.clinic.id);
          
          if(data.required_fields?.AIH){
            Object.values(data.required_fields?.AIH).map(group => {
              Object.keys(group).map((field)  => {
                requiredfield[`${field}`] = group[field];
              });
            });
          }
        }

        this.requiredField = requiredfield;        
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    onShow() {
      this.isShow = true;

      this.getLocalClinicData()
      this.updateLocalData(this.susData);

      if(this.localSusGuide && Object.values(this.localSusGuide).length > 1){
        this.updateLocalSusGuide(this.localSusGuide);  
      }
      
      if(this.localPatient && Object.values(this.localPatient).length > 1){
        this.updateLocalPatient(this.localPatient);
      }

      this.getCheckedItems();
    },
    async getLocalClinicData() {
      const isLoading = this.$loading.show();
      const { data } = await api.getInformationSusSettingByClinicId(this.clinic.id);
      
      this.api.showClinic(this.clinic.id).then(async res => {
        const resData = res.data;
        this.clinic.address = resData.address;
      });

      this.susData = data;

      isLoading.hide();      
    },
    clearModal() {
      this.form = this.getDefaultForm();
      this.procedures = [];
      this.opm_procedures = [];
      this.secondary_procedures = [];
      this.form.procedures = [];
      this.form.opm_procedures = [];
      this.form.secondary_procedures = [];
      this.errors = [];
      this.selectedIdentification =  null;
      this.selectedSusAihModality =  null;
      this.selectedNationality =  null;
      this.selectedEthnicity =  null;
      this.selectedAncestry =  null;
      this.selectedAddressState =  null;
      this.selectedExitStayReasons =  null;
      this.selectedPatientDocumentType =  null;
      this.selectedTypeAccident =  null;
      this.selectedStreetType= null;
      this.localSusGuide = null;
    },
    closeModal() {
      this.isShow = false;
      this.clearModal();
      this.$emit('onHide');
      this.$bvModal.hide('aih-sus-guide-modal');
    },
    async searchCEP() {
      if (this.form.patient?.address?.zipCode.length === 9) {
        try {
          const res = await axios.get(`https://viacep.com.br/ws/${this.form.patient.address.zipCode}/json/`);    

          this.form.patient.address.address = res.data.logradouro;
          this.form.patient.address.neighborhood = res.data.bairro;
          this.form.patient.address.state = this.statesOptions.find(item => item.uf === res.data.uf);
          this.form.patient.address.city = res.data.localidade;
          this.form.patient.address.ibge_code = res.data.ibge;

          this.$refs.patient_address_number.focus()
        } catch (error) {
          this.$toast.errpr('Erro ao buscar o endereço');
          console.error("Erro ao buscar o endereço:", error);
        }
      }
    },
    isValidForm() {
      const errors = {};

      if(!this.form.procedures.length) {   
        errors.procedures = "Campo obrigatório";     
        this.$toast.error('Obrigatório incluir procedimento a guia!');
      }

      if(!this.form.cid_1) {
        errors.cid_main_aih = "Campo obrigatório";
      }
      
      if(!this.form.requesting_establishment_name) {
        errors.requesting_establishment_name = "Campo obrigatório";
      }
      
      if(!this.form.requesting_establishment_cnes) {
        errors.requesting_establishment_cnes = "Campo obrigatório";
      }

      if(!this.form.executing_establishment_name) {
        errors.executing_establishment_name = "Campo obrigatório";
      }

      if(!this.form.executing_establishment_ibge_code) {
        errors.executing_establishment_ibge_code = "Campo obrigatório";
      }

      if(!this.form.executing_establishment_cnes) {
        errors.executing_establishment_cnes = "Campo obrigatório";
      }

      if(!this.form.identification_code) {
        // errors.identification_code = "Campo obrigatório";
      }
      
      if(!this.form.aih_number) {
        errors.aih_number = "Campo obrigatório";
      }
      
      if(!this.form.modality_code) {
        // errors.modality_code = "Campo obrigatório";
      }
      
      if(!this.form.competency) {
        errors.competency = "Campo obrigatório";
      }
      
      if(!this.form.executing_doctor) {
        errors.executing_doctor_name = "Campo obrigatório";
      }
      
      if(!this.form.number_executing_doctor) {
        errors.number_executing_doctor = "Campo obrigatório";
      }
      
      if(!this.form.name_director_establishment) {
        errors.name_director_establishment = "Campo obrigatório";
      }
      
      if(!this.form.cpf_director_establishment) {
        errors.cpf_director_establishment = "Campo obrigatório";
      }
      
      if(!this.form.indicator_executing_doctor) {
        errors.indicator_executing_doctor = "Campo obrigatório";
      }
      
      if(!this.form.name_requesting_professional) {
        errors.name_requesting_professional = "Campo obrigatório";
      }
      
      if(!this.form.indicator_requesting_professional) {
        errors.indicator_requesting_professional = "Campo obrigatório";
      }
      
      if(!this.form.document_requesting_professional) {
        errors.document_requesting_professional = "Campo obrigatório";
      }
      
      if(!this.form.date_request) {
        errors.date_request = "Campo obrigatório";
      }
      
      if(!this.form.desired_date_admission) {
        errors.desired_date_admission = "Campo obrigatório";
      }
      
      if(!this.form.authorization_date) {
        errors.authorization_date = "Campo obrigatório";
      }

      if(!this.form.authorizing_establishment_name) {
        errors.authorizing_establishment_name = "Campo obrigatório";
      }
      
      if(!this.form.indicator_authorizing_professional) {
        errors.indicator_authorizing_professional = "Campo obrigatório";
      }
      
      if(!this.form.document_authorizing_professional) {
        errors.document_authorizing_professional = "Campo obrigatório";
      }
      
      if(!this.form.issuing_body_code) {
        errors.issuing_body_code = "Campo obrigatório";
      }

      if(this.form.cpf_director_establishment && !this.isValidCPF(this.form.cpf_director_establishment)){
        errors.cpf_director_establishment = "CPF Inválido.";
      }
      
      if(this.form.patient.cpf && !this.isValidCPF(this.form.patient.cpf)){
        errors.patient_cpf = "CPF Inválido.";
      }

      if(
        this.indicator_requesting_professional && 
        this.indicator_requesting_professional === 'CPF' && 
        this.form.document_requesting_professional &&
        !this.isValidCPF(this.form.document_requesting_professional)
      ){
        errors.document_requesting_professional = "CPF Inválido.";
      }

      if(this.form.insurance_company_cnpj && !this.isValidCNPJ(this.form.insurance_company_cnpj)){
        errors.insurance_company_cnpj = "CPF Inválido.";
      }

      if(this.form.company_cnpj && !this.isValidCNPJ(this.form.company_cnpj)){
        errors.company_cnpj = "CPF Inválido.";
      }

      if(
        this.indicator_authorizing_professional && 
        this.indicator_authorizing_professional === 'CPF' && 
        this.form.document_authorizing_professional &&
        !this.isValidCPF(this.form.document_authorizing_professional)
      ){
        errors.document_authorizing_professional = "CPF Inválido.";
      }

      Object.keys(this.requiredField).map(item => {
        if(this.$refs[item] && (!this.$refs[item].value || this.$refs[item].value.length === 0) && this.requiredField[item]){
          errors[item] = "Campo obrigatório";
        }
      });

      this.errors = errors;
      return Object.keys(errors).length;
    },
    isValidCPF(cpf) {
      if (!cpf || typeof cpf !== 'string') return false;
      
      const numbers = cpf.replace(/[\D]/g, '');
      if (/^(.)\1+$/.test(numbers)) return false;
      
      let sum = 0;
      for (let i = 0; i < 9; i++) sum += parseInt(numbers[i]) * (10 - i);
      
      let digit = 11 - (sum % 11);
      if (digit > 9) digit = 0;
      
      if (parseInt(numbers[9]) !== digit) return false;
      
      sum = 0;
      for (let i = 0; i < 10; i++) sum += parseInt(numbers[i]) * (11 - i);
      
      digit = 11 - (sum % 11);
      if (digit > 9) digit = 0;
      
      return parseInt(numbers[10]) === digit;
    },
    isValidCNPJ(cnpj) {
      if (!cnpj || typeof cnpj !== 'string') return false;
      
      const numbers = cnpj.replace(/[\D]/g, '');
      if (/^(.)\1+$/.test(numbers)) return false;
      
      let sum = 0;
      let factor = 5;
      for (let i = 0; i < 12; i++) {
        sum += parseInt(numbers[i]) * factor--;
        if (factor < 2) factor = 9;
      }
      
      let digit = 11 - (sum % 11);
      if (digit > 9) digit = 0;
      
      if (parseInt(numbers[12]) !== digit) return false;
      
      sum = 0;
      factor = 6;
      for (let i = 0; i < 13; i++) {
        sum += parseInt(numbers[i]) * factor--;
        if (factor < 2) factor = 9;
      }
      
      digit = 11 - (sum % 11);
      if (digit > 9) digit = 0;
      
      return parseInt(numbers[13]) === digit;
    },
    getPayload() {
      const payload = {
        patient: {
          ...this.form.patient,   
          nationality: this.selectedNationality ? this.selectedNationality.value : null,
          ancestry: this.selectedAncestry ? this.selectedAncestry.value :  null,
          ethnicity: this.selectedEthnicity ? this.selectedEthnicity.value :  null,
        },
        address: {
          ...this.form.patient.address,
          state: this.selectedAddressState ? this.selectedAddressState.acronym : null
        },
        procedures: this.form.procedures,
        opm_procedures: this.form.opm_procedures,
        secondary_procedures: this.form.secondary_procedures,
        guide_type: "AIH",
        sus_information_id: this.susData.id,
        clinic_id: this.clinic.id,
        clinic_health_plan_id: this.susData.clinic_health_plan.id,
        requesting_establishment_name: this.form.requesting_establishment_name,
        requesting_establishment_cnes: this.form.requesting_establishment_cnes,
        executing_establishment_name: this.form.executing_establishment_name,
        executing_establishment_ibge_code: this.form.executing_establishment_ibge_code,
        executing_establishment_cnes: this.form.executing_establishment_cnes,
        identification: this.form.identification,
        identification_code: this.selectedIdentification?.code,
        aih_number: this.form.aih_number,
        modality: this.form.modality,
        modality_code: this.selectedSusAihModality?.code,
        competency: this.form.competency,
        executing_doctor_name: this.form.executing_doctor_name,
        cns_executing_doctor: this.form.cns_executing_doctor,
        number_executing_doctor: this.form.number_executing_doctor,
        patient_id: this.form.patient.id,
        main_clinical_features: this.form.main_clinical_features,
        conditions_justifying_admission: this.form.conditions_justifying_admission,
        main_results_diagnostic_tests: this.form.main_results_diagnostic_tests,
        initial_diagnosis: this.form.initial_diagnosis,
        cid_1: this.form.cid_1?.id, 
        cid_2: this.form.cid_2?.id, 
        cid_associate: this.form.cid_associate?.id,
        compulsory_reporting: this.form.compulsory_reporting,
        date_admission: this.form.date_admission,
        exit_date: this.form.exit_date,
        name_director_establishment: this.form.name_director_establishment,
        cpf_director_establishment: this.form.cpf_director_establishment,
        director_council_number: this.form.director_council_number,
        indicator_executing_doctor: this.form.indicator_executing_doctor,
        name_requesting_professional: this.form.name_requesting_professional,
        indicator_requesting_professional: this.form.indicator_requesting_professional,
        document_requesting_professional: this.form.document_requesting_professional,
        requesting_council_number: this.form.requesting_council_number,
        date_request: this.form.date_request,
        desired_date_admission: this.form.desired_date_admission,
        authorization_date: this.form.authorization_date,
        insurance_company_cnpj: this.form.insurance_company_cnpj,
        ticket_number: this.form.ticket_number,
        serie: this.form.serie,
        link_social_security: this.form.link_social_security,
        company_cnpj: this.form.company_cnpj,
        cnae_company: this.form.cnae_company,
        cbor_company: this.form.cbor_company ? this.form.cbor_company : ' ',
        authorizing_establishment_name: this.form.authorizing_establishment_name,
        indicator_authorizing_professional: this.form.indicator_authorizing_professional,
        document_authorizing_professional: this.form.document_authorizing_professional,
        issuing_body_code: this.form.issuing_body_code,
        authorizing_council_number: this.form.authorizing_council_number,   
        type_accident_code: this.form.type_accident_code,   
        professional_id: this.form.executing_doctor?.id,    
      };
      return payload;
    },
    async createGuide() {
      if (this.isValidForm()) {
        this.$toast.warning('Preencha todos os campos obrigatórios.')
        return
      }
      
      const isLoading = this.$loading.show()
      try {
        const payload = this.getPayload();
        
        const { data } = await api.createSusGuide(payload);

        this.$emit('refresh-tiss-guides', {
          id: data.id,
          guide_id: data.guide_id,
          isSus: true
        });
        
        this.$toast.success('Guia AIH criada com sucesso!')
        this.closeModal();        
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async updatesusGuide(){
      if (this.isValidForm()) {
        this.$toast.warning('Preencha todos os campos obrigatórios.')
        return
      }
      
      const isLoading = this.$loading.show()
      try {
        const payload = this.getPayload();
        
        const { data } = await api.updateSusGuide(this.localSusGuide.id, payload);

        this.$emit('refresh-tiss-guides', {
          id: data.id,
          guide_id: data.guide_id,
          isSus: true
        });
        
        this.$toast.success('Guia AIH atualizada com sucesso!')
        this.closeModal();        
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    updateProcedures(procedures) {
      this.form.procedures = procedures;
    },
    updateOpmProcedures(procedures) {
      this.form.opm_procedures = procedures;
    },
    updateSecondaryProcedures(procedures) {
      this.form.secondary_procedures = procedures;
    },
    async searchNationality(query) {
      if (query.length > 2) {
        this.nationalities = []
        try {
          const response = await this.api.searchNationality({ term: query })
          this.nationalities = response.data.map(nationality => {
            return {
              label: `${nationality.value}`,
              id: nationality.id
            }
          })
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    async searchCid10(query) {
      if (query.length > 2) {
        this.cid10 = []
        try {
          const response = await this.api.searchCid10({ term: query })
          this.cid10 = response.data.map(cid10 => {
            return {
              label: `${cid10?.code} - ${cid10.description}`,
              id: cid10.id
            }
          })
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    async searchCid10Sec(query) {
      if (query.length > 2) {
        this.cid10_sec = []
        try {
          const response = await this.api.searchCid10({ term: query })
          this.cid10_sec = response.data.map(cid10 => {
            return {
              label: `${cid10?.code} - ${cid10.description}`,
              id: cid10.id
            }
          })
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    async searchAssociatedCauses(query) {
      if (query.length > 2) {
        this.cid10_associated_causes = []
        try {
          const response = await this.api.searchCid10({ term: query })
          this.cid10_associated_causes = response.data.map(cid10 => {
            return {
              label: `${cid10?.code} - ${cid10.description}`,
              id: cid10.id
            }
          })
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    selectCid_1() {
      this.cid_1 = []
    },
    selectCid_2() {
      this.cid_2 = []
    },
    selectAssociatedCauses() {
      this.cid_associate = [];
    },
    async print() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await api.printSusGuide(this.localSusGuide.id, getCurrentUser()?.name) 
        const blob = new Blob([data], { type: 'application/pdf' })
        saveAs(blob, 'Guia AIH')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    updateLocalSusGuide(susGuide) {    
      if (Object.values(susGuide).length > 0) {
          const isLoading = this.$loading.show();
          this.form = {
            ...susGuide,
            procedures: { ...susGuide?.guide?.procedures || [] },
            opm_procedures: { ...susGuide?.guide?.opm_procedures || [] },
            secondary_procedures: { ...susGuide?.guide?.secondary_procedures || [] },
            guide_number: susGuide?.guide?.guide_number,
            requesting_establishment_name: susGuide?.guide?.requesting_establishment_name,
            requesting_establishment_cnes: susGuide?.guide?.requesting_establishment_cnes,
            executing_establishment_name: susGuide?.guide?.executing_establishment_name,
            executing_establishment_ibge_code: susGuide?.guide?.executing_establishment_ibge_code,
            executing_establishment_cnes: susGuide?.guide?.executing_establishment_cnes,
            identification_code: susGuide?.guide?.identification_code,
            aih_number: susGuide?.guide?.aih_number,
            modality_code: susGuide?.guide?.modality_code,
            competency: susGuide?.competency ? moment(susGuide.competency.replace('/', '-')).toDate() : null,
            executing_doctor_name: susGuide?.guide?.executing_doctor_name,
            cns_executing_doctor: susGuide?.guide?.cns_executing_doctor,
            number_executing_doctor: susGuide?.guide?.number_executing_doctor,
            patient_id: susGuide?.guide?.patient_id,
            main_clinical_features: susGuide?.guide?.main_clinical_features,
            conditions_justifying_admission: susGuide?.guide?.conditions_justifying_admission,
            main_results_diagnostic_tests: susGuide?.guide?.main_results_diagnostic_tests,
            initial_diagnosis: susGuide?.guide?.initial_diagnosis,
            cid_1: this.formatCid(susGuide?.guide?.cid1),
            cid_2: this.formatCid(susGuide?.guide?.cid2),
            cid_associate: this.formatCid(susGuide?.guide?.cid_associate),            
            compulsory_reporting: susGuide?.guide?.compulsory_reporting,
            bed_specialty_code: susGuide?.guide?.bed_specialty_code,
            date_admission: this.formatDate(susGuide?.guide?.date_admission),
            exit_date: this.formatDate(susGuide?.guide?.exit_date),
            name_director_establishment: susGuide?.guide?.name_director_establishment,
            cpf_director_establishment: susGuide?.guide?.cpf_director_establishment,
            director_council_number: susGuide?.guide?.director_council_number,
            indicator_executing_doctor: susGuide?.guide?.indicator_executing_doctor,
            name_requesting_professional: susGuide?.guide?.name_requesting_professional,
            indicator_requesting_professional: susGuide?.guide?.indicator_requesting_professional,
            document_requesting_professional: susGuide?.guide?.document_requesting_professional,
            requesting_council_number: susGuide?.guide?.requesting_council_number,
            date_request: this.formatDate(susGuide?.guide?.date_request),
            desired_date_admission: this.formatDate(susGuide?.guide?.desired_date_admission),
            authorization_date: this.formatDate(susGuide?.guide?.authorization_date),            
            insurance_company_cnpj: susGuide?.guide?.insurance_company_cnpj,
            ticket_number: susGuide?.guide?.ticket_number,
            serie: susGuide?.guide?.serie,
            link_social_security: susGuide?.guide?.link_social_security,
            company_cnpj: susGuide?.guide?.company_cnpj,
            cnae_company: susGuide?.guide?.cnae_company,
            cbor_company: susGuide?.guide?.cbor_company,
            authorizing_establishment_name: susGuide?.guide?.authorizing_establishment_name,
            indicator_authorizing_professional: susGuide?.guide?.indicator_authorizing_professional,
            document_authorizing_professional: susGuide?.guide?.document_authorizing_professional,
            issuing_body_code: susGuide?.guide?.issuing_body_code,
            authorizing_council_number: susGuide?.guide?.authorizing_council_number,   
            type_accident_code: susGuide?.guide?.type_accident_code,
            executing_doctor: susGuide?.professional || {},
          };

          this.updateLocalPatient(susGuide?.patient);

          if(susGuide?.guide?.identification_code){
            this.selectedIdentification = this.identificationOptions.find(item => item.code === susGuide?.guide?.identification_code);
            this.changeSelectedIdentification(this.selectedIdentification);
          }

          if(susGuide?.guide?.modality_code){
            this.selectedSusAihModality = this.modalityOptions.find(item => item.code === susGuide?.guide?.modality_code);
            this.changeSelectedSusAihModality(this.selectedSusAihModality);
          }
          
          if(susGuide?.guide?.type_accident_code){
            this.selectedTypeAccident = susGuide?.guide.type_accident_code;
          }

          if(susGuide?.professional){
            this.form.executing_doctor_name = susGuide.professional.name;
            this.form.indicator_executing_doctor = 'CNS';
            this.form.number_executing_doctor = susGuide.professional.sus_professional_setting.cns;
          }

          if(susGuide?.guide?.procedures){
            this.procedures = susGuide.guide?.procedures.map(item => ({
                'id': item.id,
                'item': item.item,
                'bed_specialty_code': item.bed_specialty_code,
                'date_admission': moment(item.date_admission).toDate(),
                'exit_date': moment(item.exit_date).toDate(),
                'exit_stay_reason_code': item.exit_stay_reason_code,
                'change_procedure_code': item.change_procedure_code,
                'clinic_requested_procedure': item.clinic_requested_procedure,
                'supplementary_bed': item.supplementary_bed,
                'equipment_required_hospitalization': item.equipment_required_hospitalization,
                'character_hospitalization_code': item.character_hospitalization_code,
                'item_id': item.item_id,
                'quantity': item.quantity,
                'unitary_value': item.unitary_value,
                'total_value': item.total_value
            
            }));

            this.form.procedures = this.procedures;
          }

          if(susGuide?.guide?.opm_procedures){
            this.opm_procedures = susGuide.guide?.opm_procedures.map(item => ({
                'id': item.id,
                'item': item.item,
                'opme_code': item.opme_code,
                'registration_anvisa': item.registration_anvisa,
                'serial_number': item.serial_number,
                'product_batch': item.product_batch,
                'invoice_number': item.invoice_number,
                'manufacturer_cnpj': item.manufacturer_cnpj,
                'supplier_cnpj': item.supplier_cnpj,            
            }));

            this.form.opm_procedures = this.opm_procedures;
          }

          if(susGuide?.guide?.secondary_procedures){
            this.secondary_procedures = susGuide.guide?.secondary_procedures?.map(item => ({
              'id': item.id,
              'item': item.item,
              'aih_guide_id': item.aih_guide_id,
              'code': item.code,
              'professional_document_number': item.professional_document_number,
              'professional_cbo': item.professional_cbo,
              'identification_service_provider': item.identification_service_provider,
              'executor_document': item.executor_document,
              'professional_indicator_code': item.professional_indicator_code,
              'team_indicator_code': item.team_indicator_code,
              'service_provider_indicator_code': item.service_provider_indicator_code,
              'executor_provider_indicator_code': item.executor_provider_indicator_code,
              'item_id': item.item_id,
              'quantity': item.quantity,
              'unitary_value': item.unitary_value,
              'total_value': item.total_value,
            }));            
            this.form.secondary_procedures = this.secondary_procedures;
          }

          isLoading.hide();
      }
    },
    updateLocalPatient(patient) {
      this.form.patient = {
        ...patient,
        address: patient.address || {
          zipCode: '',
          ibge_code: null,
          street_code: null,
          address: '',
          complement: '',
          neighborhood: ''
        }
      };   

      if(patient?.ancestry){
        this.selectedAncestry = {
            label: `${patient.ancestry.code} - ${patient.ancestry.name}`,
            code: patient.ancestry.code,
            id: patient.ancestry.id
        };
      }

      if(patient?.ethnicity){
        this.selectedEthnicity = {
            label: `${patient.ethnicity.code} - ${patient.ethnicity.name}`,
            code: patient.ethnicity.code,
            id: patient.ethnicity.id
        };
      }

      if(patient?.address?.state){
        this.selectedAddressState = {
            label: `${patient.address.state.code_ibge} - ${patient.address.state.name}`,
            code: patient.address.state.code_ibge,
            id: patient.address.state.id
        };
      }

      const streetType = patient?.address?.street_type;
      if (streetType) {
        this.selectedStreetType = this.streetTypeOptions.find(item => item.code === streetType);
      }
      
      if(patient?.nationality){
        this.selectedNationality = {
          label: `${patient.nationality.code} - ${patient.nationality.value}`,
          code: patient.nationality.code,
          id: patient.nationality.id
        };
        if(!patient.nationality.is_sus && this.isShow){
          this.$toast.error('Nacionalidade não é aceita pelo convênio');
        }
      }
    },
    updateLocalData (susData){
      this.form.executing_establishment_name = this.clinic?.name;
      this.form.executing_establishment_ibge_code = this.clinic?.address?.ibge_code;
      this.form.executing_establishment_cnes = susData?.cnaes_code;

      this.form.requesting_establishment_name = this.clinic?.name;
      this.form.requesting_establishment_cnes = susData?.cnaes_code;

      this.newGuideNumber = susData?.current_aih_guide_number + 1;
    },
    getAncestries() {
      if (this.ancestryOptions.length === 0) {
        this.api.getAncestries().then(async res => {
          this.ancestryOptions = res.data.map(type => {
            return {
              label: `${type?.code} - ${type.name}`,
              code: type.code,
              id: type.id
            }
          })
        })
      }
    },
    getEthnicities() {
      if (this.ethnicityOptions.length === 0) {
        this.api.getEthnicities().then(async res => {
          this.ethnicityOptions = res.data.map(type => {
            return {
              label: `${type?.code} - ${type.name}`,
              code: type.code,
              id: type.id
            }
          })
        })
      }
    },
    getNationalities() {
      if (this.nationalities.length === 0) {
        this.api.getNationalities({is_sus: true}).then(async res => {
          this.nationalities = res.data.map(type => {
            return {
              label: `${type?.code} - ${type.value}`,
              code: type.code,
              id: type.id
            }
          })
        })
      }
    },
    getStates() {
      if (this.statesOptions.length === 0) {
        this.api.getStates().then(async res => {
          this.statesOptions = res.data.map(type => {
            return {
              label: `${type?.code_ibge} - ${type.name}`,
              code: type.code_ibge,
              id: type.id
            }
          })
        })
      }
    },
    getExitStayReasons() {
      if (this.exitStayReasonOptions.length === 0) {
        this.api.getExitStayReasons().then(async res => {
          this.exitStayReasonOptions = res.data.map(type => {
            return {
              label: `${type?.code} - ${type.description}`,
              code: type.code,
              id: type.id
            }
          })
        })
      }
    },
    changeSelectedIdentification(value) {
      this.form.identification_code = value.code;
		},
    changeSelectedSusAihModality(value){
      this.form.modality_code = value.code;      
    },
    changeSelectedNationality(value) {
      this.form.patient.nationality_id = value.id;
		},
    changeSelectedEthnicity(value) {
      if(value.code !== "05"){
        this.form.patient.ancestry = null;
        this.form.patient.ancestry_id = null;
        this.selectedAncestry = null;
      }
      this.form.patient.ethnicity_id = value.id;
		},
    changeSelectedAncestry(value) {
      this.form.patient.ancestry_id = value.id;
		},
    changeSelectedAddressState(value) {
      this.form.patient.address.state_id = value.id;
		},
    changeSelectedExitStayReasons(value){
      this.form.exit_stay_reasons_code = value.code;            
    },
    changeSelectedPatientDocumentType(value){
      this.form.patient_document_type_code = value.code;            
    },
    changeSelectedTypeAccident(value) {
      // this.form.type_accident_code = value.label;         
    },
    changeSelectedStreetType(value) {
      this.form.patient.address.street_type = value.code;
    },
    updateProfessional(professional) {
      this.form.executing_doctor = professional;
      this.form.executing_doctor_name = professional.label;
      this.form.indicator_executing_doctor = 'CNS';
      this.form.number_executing_doctor = professional.cns;
    },
  },
  watch: {
    susGuide: {
      handler(susGuide) {
          this.localSusGuide = { ... susGuide }
          this.updateLocalSusGuide(this.localSusGuide);
          this.isReadonly();
        },
        immediate: true,
    },
    patient: {
      handler(patient) {
        if (patient) {
          this.localPatient = { ...patient }
          this.updateLocalPatient(this.localPatient);
        }
      },
      immediate: true,
    },
    susData: {
      handler (susData) {
        if (susData) {
          this.updateLocalData(susData);
        }
      },
      immediate: true,
      deep: true,
    }
  },
};
</script>

<style lang="scss">
#aih-sus-guide-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }

    .around-content {
      padding: 24px 24px 0 24px;

      .multiselect {
        color: var(--type-active) !important;
      }

      .help-icon {
        svg {
          height: 20px;
          padding-bottom: 3px;
        }
      }

    }

    .wh-button {
      color: var(--blue-500);
    }

    .invalid-feedback {
      display: block;
    }
  }

  .wrapper-button {
    width: 100%;
    display: inline-flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: var(--neutral-000);
    }
  }

  .width-80 {
    width: 80%;
  }

  .form-title{
    background: #F4F5FB;
    color: var(--surface-brand, #305BF2);
    font-size: 18px;
    font-weight: bolder;
    padding: 12px;
  }

  .hr {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .check-disable {
    background-color: var(--neutral-100) !important;
    border: 1px solid var(--neutral-300) !important;
  }
}

:root .multiselect .multiselect__single {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 15px);
}
</style>
