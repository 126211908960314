<template>
  <b-row class="mt-3">
    <b-col cols="12">
      <p class="form-title mb-3">Procedimento Secundário</p>
    </b-col>
    
    <b-col cols="12">
      <b-table-simple fixed class="table" responsive borderless>
        <thead>
          <tr>
            <th>Cód. proced.</th>
            <th>Descrição</th>
            <th>Qtde.</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in procedures" :key="index">
            <td>{{ item.item?.code }}</td>
            <td>{{ item.item?.name }}</td>
            <td>{{ item.quantity }}</td>
            <td class="text-right d-flex">
              <Edit v-if="readonly" class="edit" @click="editProcedure(item, index)" />
              <Delete v-if="readonly" class="delete" @click="confirmDelete(index)" />
            </td>
          </tr>
        </tbody>
      </b-table-simple>             
    </b-col>

    <SecondaryProcedureAihModal
      v-if="type === 'aih'"
      :procedure="selectedProcedure"
      :readonly="readonly"
      @update-secondary-procedures="updateProcedure"
      :clinicHealthPlanId="clinicHealthPlanId"
      :requestFields="requestFields"
      :isValidCPF="isValidCPF"
      :isValidCNPJ="isValidCNPJ"
    />

    <b-col cols="12">
      <div class="col-12 text-center">
        <hr id="hr" />
        <b-button variant="link" :disabled="!readonly" @click="openModalToAdd">
          Adicionar procedimento
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import SecondaryProcedureAihModal from './SecondaryProcedureAihModal.vue';
import moment from 'moment';

export default {
  name: 'AihProcedure',
  components: {
    Edit: () => import('@/assets/icons/edit.svg'),
    Delete: () => import('@/assets/icons/delete.svg'),
    SecondaryProcedureAihModal
  },
  props: {
    type: String,
    checkedItems: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clinicHealthPlanId: String,
    requestFields: [],
    isValidCPF: {
      type: Function,
      default: () => {}
    },
    isValidCNPJ: {
      type: Function,
      default: () => {}
    },
  },
  data() {
    return {
      procedures: [],
      selectedProcedure: {},
    };
  },
  created() {
    this.initializeProcedures();
  },
  watch: {
    checkedItems: {
      handler(newVal) {
          if (newVal) {
              this.checkedItems = newVal;
          }
        },
        immediate: true,
        deep: true
      },
  },
  methods: {
    formatDate(date) {
      return date && moment(date).isValid() ? moment(date).format('DD/MM/YYYY') : '';
    },
    initializeProcedures() {
      this.procedures = this.checkedItems;
      this.$emit('update-secondary-procedures', this.checkedItems);
    },
    updateProcedure(updatedProcedure) {      
      if (updatedProcedure.index !== undefined) {
        this.$set(this.procedures, updatedProcedure.index, updatedProcedure);
      } else {
        this.procedures.push(updatedProcedure);
      }

      this.$bvModal.hide('secondary-procedure-' + this.type + '-modal');
      this.$emit('update-secondary-procedures', this.procedures);
    },
    openModalToAdd() {
      this.selectedProcedure = {};
      this.$bvModal.show('secondary-procedure-' + this.type + '-modal');
    },
    editProcedure(item, index) {
      item.index  = index;
      this.selectedProcedure = { ...item };
      this.$bvModal.show('secondary-procedure-' + this.type + '-modal');
    },
    confirmDelete(index) {
      if(this.procedures[index].appointment_item_id){
        this.$toast.error("Esse item está vinculado ao atendimento, e não pode ser removido.");
        return;
      }

      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente remover este procedimento?</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
      }).then(res => {
        if (res.isConfirmed) {
          this.procedures.splice(index, 1);
          this.$emit('update-secondary-procedures', this.procedures);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';

.table {
  thead {
    th {
      font-size: 13.5px !important;
    }
  }
  tbody {
    tr {
      td {
        font-size: 13.5px !important;
      }
    }
  }
}

.edit, .delete {
  width: 24px !important;
  height: 24px !important;
  cursor: pointer;
}

.edit {
  stroke: var(--blue-500);
}

.delete {
  stroke: var(--red-500);
}
</style>
