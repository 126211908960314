<template>
    <b-modal
      id="procedure-aih-opm-modal"
      hide-header
      hide-footer
      centered
      size="lg"
      @show="onShow"
      @hidden="closeModal"
    >
      <div class="modal-header">
        <div class="modal-title">
          <span v-if="procedure?.id">Atualizar</span>
          <span v-else>Novo Procedimento</span>
        </div>
        <span class="icon-close">
          <Close class="icon stroke" @click="closeModal" />
        </span>
      </div>
  
      <div class="around-content">
        <b-row class="mt-3">
          <b-col cols="12">
            <p class="form-title mb-3">
              Dados da execução do procedimento
            </p>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Código do OPM">
              <b-form-input
                v-model="procedure.opme_code"
                placeholder="000000000000000"
                autocomplete="off"
                v-mask="'##########'"
                :state="errors.opm_code ? false : null"
                ref="opm_code"
              />
              <b-form-invalid-feedback v-if="errors?.opm_code">
                {{ errors.opm_code }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Registro na ANVISA">
              <b-form-input
                v-model="procedure.registration_anvisa"
                placeholder="Descrever"
                autocomplete="off"
                :state="errors.registration_anvisa ? false : null"
                ref="registration_anvisa"
              />
              <b-form-invalid-feedback v-if="errors?.registration_anvisa">
                {{ errors.registration_anvisa }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Número de série">
              <b-form-input
                v-model="procedure.serial_number"
                placeholder="Descrever"
                autocomplete="off"
                :state="errors.serial_number ? false : null"
                ref="serial_number"
              />
              <b-form-invalid-feedback v-if="errors?.serial_number">
                {{ errors.serial_number }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Lote do produto">
              <b-form-input
                v-model="procedure.product_batch"
                placeholder="Descrever"
                autocomplete="off"
                :state="errors.product_batch ? false : null"
                ref="product_batch"
              />
              <b-form-invalid-feedback v-if="errors?.product_batch">
                {{ errors.product_batch }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Número da nota fiscal">
              <b-form-input
                v-model="procedure.invoice_number"
                placeholder="Descrever"
                autocomplete="off"
                :state="errors.invoice_number ? false : null"
                ref="invoice_number"
              />
              <b-form-invalid-feedback v-if="errors?.invoice_number">
                {{ errors.invoice_number }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="CNPJ do fornecedor">
              <b-form-input
                v-model="procedure.supplier_cnpj"
                placeholder="000000000000000"
                v-mask="'##.###.###/####-##'"
                autocomplete="off"
                :state="errors.supplier_cnpj ? false : null"
                ref="supplier_cnpj"
              />
              <b-form-invalid-feedback v-if="errors?.supplier_cnpj">
                {{ errors.supplier_cnpj }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="CNPJ do fabricante">
              <b-form-input
                v-model="procedure.manufacturer_cnpj"
                placeholder="000000000000000"
                v-mask="'##.###.###/####-##'"
                autocomplete="off"
                :state="errors.manufacturer_cnpj ? false : null"
                ref="manufacturer_cnpj"
              />
              <b-form-invalid-feedback v-if="errors?.manufacturer_cnpj">
                {{ errors.manufacturer_cnpj }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
  
      <div class="wrapper-button text-center mt-3">
        <b-button variant="primary" size="lg" @click="saveProcedure" v-if="readonly">
          Salvar procedimento
        </b-button>
      </div>
    </b-modal>
  </template>
  
  <script>

  export default {
    name: 'ProcedureAihOpmModal',
    components: {
      Close: () => import('@/assets/icons/close.svg'),
    },
    data() {
      return {
        errors: {}
      }
    },
    props: {
      type: String,
      procedure: {
        type: Object,
        default: () => ({}),
      },
      readonly: Boolean,
      clinicHealthPlanId: String,
      requestFields: [],
      isValidCPF: {
        type: Function,
        default: () => {}
      },
      isValidCNPJ: {
        type: Function,
        default: () => {}
      },
      },
    mounted (){
      // 
    },
    methods: {
      setGuideItem(item) {
        this.procedure.item = item;
        this.procedure.code = item.code;
        this.procedure.item_id = item.id;
        this.procedure.name = item.name;
        this.procedure.quantity = 1;
        this.procedure.unitary_value = item.health_plan_props.value;
      },
      onShow() {
      },
      closeModal() {
        this.$bvModal.hide('procedure-aih-opm-modal')
      },
      saveProcedure() {
        if (this.isValidForm()) {
          this.$toast.warning('Preencha todos os campos obrigatórios.')
          return
        }
        this.$emit('update-opm-procedures', { 
          ...this.procedure,
        });
        this.closeModal();
      },
      isValidForm() {
        const errors = {};

        if(
          !this.procedure.invoice_number && 
          !this.procedure.manufacturer_cnpj && 
          !this.procedure.opme_code && 
          !this.procedure.product_batch && 
          !this.procedure.registration_anvisa && 
          !this.procedure.serial_number && 
          !this.procedure.supplier_cnpj
      ){
        this.$toast.warning('Não é permitido adicionar registro de OPM vazio');
        errors.fields = 'Não é permitido adicionar registro de OPM vazio';
      }

        if(this.procedure.supplier_cnpj && !this.isValidCNPJ(this.procedure.supplier_cnpj)){
          errors.supplier_cnpj = "CNPJ Inválido.";
        }

        if(this.procedure.manufacturer_cnpj && !this.isValidCNPJ(this.procedure.manufacturer_cnpj)){
          errors.manufacturer_cnpj = "CNPJ Inválido.";
        }

        Object.keys(this.requestFields).map(item => {
          if(this.$refs[item] && (!this.$refs[item].value || this.$refs[item].value.length === 0) && this.requestFields[item]){
            errors[item] = "Campo obrigatório";
          }
        });
        
        this.errors = errors;
        
        return Object.keys(errors).length;
      }
    },
    watch: {
      // 
    }
  };
  </script>
  <style lang="scss">
    #procedure-aih-opm-modal {
      .form-title {
        color: var(--neutral-600);
        font-size: 18px;
        font-weight: bolder;
        line-height: 28px;
      }
      .modal-header {
        .modal-title {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
        }
        
        .icon-close {
          width: 25px;

          svg {
            fill: var(--neutral-500);
          }
        }

      }
      
      .col-form-label {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          color: #0a184c;
          margin: 0;
          margin-bottom: 0px;
      }

      .invalid-feedback{
        display: block;
      }
  }
  </style>